import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import { MapLayer } from 'data/capas/capas'
import Badge, { getBadgeBg, getStatusDisplay } from 'components/base/Badge';
import { Button } from 'react-bootstrap';

export const layerListTableColumns: ColumnDef<MapLayer>[] = [
  {
    accessorKey: 'layerName',
    header: 'Nombre Capa',
    cell: ({ row: { original }}) => {
      const { layerName, _id } = original;
      return (
        <Link to={'../poligonos-edit/' + _id } className="text-decoration-none fs-12">
          { layerName.toUpperCase() }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    accessorKey: 'layerInsertDate',
    header: 'Fecha de registro',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-2' },
      headerProps: { style: { width: '30%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Agregada por',
    accessorKey: 'layerOwner',
    cell: ({ row: { original }}) => {
      const { layerOwner } = original;
      return (
        <Link to="#!" className="text-decoration-none fs-12">
          { layerOwner.firstName + " " + layerOwner.lastName }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '20%' }, className: 'ps-1' }
    }
  },
  {
    id: 'layerStatus',
    header: 'Polígonos',
    accessorFn: ({ layerStatus }) => layerStatus,
    cell: ({ row: { original } }) => {
      const { layerStatus } = original;
      return (
        <Badge variant="phoenix" bg={getBadgeBg(layerStatus)}>
          {getStatusDisplay(layerStatus)}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '20%' }, className: 'ps-8' }
    }
  }
];

const LayerListTablePoligono = () => {
  return (
    <div className="border-bottom border-translucent">
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }}
        />
        <AdvanceTableFooter pagination className="py-3" />
      </div>
  );
};

export default LayerListTablePoligono;