import { Card, Col, Row, Spinner } from 'react-bootstrap';
import PublicTopRegionsMap from 'components/leaflet-maps/PublicTopRegionsMap';
import { MapMarker, fetchPublicMarkers } from 'data/puntos/puntos';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';
import { useEffect, useState } from 'react';
//import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import img30 from 'assets/img/generic/30.jpg';
import img31 from 'assets/img/generic/31.jpg';
import img32 from 'assets/img/generic/32.jpg';
import img33 from 'assets/img/generic/33.jpg';
import img34 from 'assets/img/generic/34.jpg';
import img35 from 'assets/img/generic/35.jpg';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { SwiperClass, SwiperSlide } from 'swiper/react';
import Swiper from 'components/base/Swiper';

const PublicMap = () => {
  const [loading, setLoading] = useState(true);
  
  const [markerData, setMarkerData] = useState<MapMarker[]>([]);
  const [polygonData, setPolygonData] = useState<MapPolygon[]>([]);

  const [show, setShow] = useState(false);

  const [selectedMarker, setSelectedMarker] = useState<MapMarker>();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setTimeout(() => handleShow(), 1000);

    console.log(process.env.EMILIA_BACKEND_URL)
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const data = await fetchPublicMarkers();
        setMarkerData(data);
        //const pData = await fetchPolygons();
        //setPolygonData(pData);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    if (!selectedMarker) return; // this prevents the popup opening if the dashboard is still loading 
    
    console.log("Selected marker change: " + selectedMarker._id)

    setSelectedMarker(undefined); // this allows the user press the pics button again
  }, [selectedMarker]);

  return (
  <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
    <>{ /*
      <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Acuerdo de uso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>La información contenida en esta demostración es solamente para uso de estudio y no representa por ningún tipo información oficial.</p>
        La ubicación del dispositivo ha sido restringida por su versión de demostración<br /><br />
        <p><i>Las funciones disponibles para su licencia de demostración son:</i></p>
        <ul>
          <li>Ver información de pruebas</li>
          <li>Capas de mapas</li>
          <li>Puntos o marcas GIS</li>
          <li>Layers / Mapas precargados</li>
        </ul>
      </Modal.Body>   
    </Modal> */ }
      <Row className="g-1">
        <Col xs={12} xxl={6}>
          <h2 className="mb-1">Resumen informativo</h2>
          <h5 className="text-body-tertiary fw-semibold">
          Resumen de registros
          </h5>
        </Col>
      </Row>
      <Row className="g-1">
        <Col xs={12} sm={12} md={3} xl={3} xxl={3} lg={3}>
          <Card>
            <div style={{ minHeight: 350 }}>
              <Swiper
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
                  }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  >
                {[img30, img31, img32, img33, img34, img35].map(
                  (image, index) => (
                    <SwiperSlide className="h-auto" key={index}>
                      <div className="pb-1">
                        <img src={image} alt="" className="img-fluid rounded-1" />
                      </div>
                      [publicData]<br />
                      [{image}]
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={9} xl={9} xxl={9} lg={9}>
          <Card className="h-100 w-100">
            <div style={{ minHeight: 350 }}>
              <PublicTopRegionsMap markerData={markerData} polygonData={polygonData} setSelectedMarker={setSelectedMarker}/>
            </div>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} xxl={12} lg={12}>
          <Card>
            <div style={{maxHeight:100}}>
              <Swiper
                onInit={setThumbsSwiper}
                spaceBetween={3}
                slidesPerView={9}
                freeMode={true}
                grabCursor={true}
                navigation={false}
              >
                {[img30, img31, img32, img33, img34, img35].map(
                  (image, index) => (
                    <SwiperSlide className="h-auto" key={index} style={{maxHeight:100}}>
                      <img src={image} alt="" className="img-fluid rounded-1" />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </Card>
        </Col>
      </Row>
      </>
    )}
    </>
  );
};

export default PublicMap;