import { useContext, useEffect, useState } from 'react';
import L, { LatLng, LatLngExpression } from 'leaflet';
import { MapContainer, Marker, Popup, useMap, useMapEvents, LayersControl, LayerGroup } from 'react-leaflet';
import '../../../node_modules/leaflet.tilelayer.colorfilter/src/leaflet-tilelayer-colorfilter';
import '../../../node_modules/leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import { CategoryRoot, MapMarker } from 'data/puntos/puntos';
import { AppContext } from 'providers/AppProvider';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { MapLayer } from 'data/capas/capas';
import { getMarkerColor } from 'helpers/utils';

import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";

// @ts-ignore
import Locate from 'leaflet.locatecontrol';

import CrossAnimation from 'pages/apps/poligonos/modal_anim/CrossAnimation';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import MarkerClusterGroup from 'react-leaflet-cluster';
import { SuperClustering } from 'react-leaflet-supercluster';

const LayerComponent = ({ data, onGeolocationChange }: { data: MapMarker[], onGeolocationChange: any }) => {
  const [layerGroups, setLayerGroups] = useState<{ [ key: string]: { layer: MapLayer; markers: MapMarker[]}} >({});
  const [creationMarker, setCreationMarker] = useState<LatLng | null>(null);
  
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  
  const [easyButtonAdded, setEasyButtonAdded] = useState(false);

  useEffect(() => {
    const groupedMarkersObj: { [key: string]: { layer: MapLayer; markers: MapMarker[] } } = {};

    data.forEach(marker => {
      const layerName = marker.markerLayer?.layerName || 'Capa por defecto';
      if (!groupedMarkersObj[layerName]) {
        groupedMarkersObj[layerName] = {
          layer: marker.markerLayer,
          markers: []
        };
      }

      groupedMarkersObj[layerName].markers.push(marker);
    });

    setLayerGroups(groupedMarkersObj);
  }, [data]);

  const map = useMapEvents({
    click(event) {
      const latlng: LatLng = event.latlng;

      onGeolocationChange(latlng.lat, latlng.lng);

      setCreationMarker(latlng);
    },
    locationfound: (event) => {
      map.flyTo(event.latlng, 13.5);
    }
  });
  const { config } = useContext(AppContext);

  const { theme } = config;
  const filter =
    theme === 'dark'
      ? [
          'invert:98%',
          'grayscale:69%',
          'bright:89%',
          'contrast:111%',
          'hue:205deg',
          'saturate:1000%'
        ]
      : ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];

  useEffect(() => {
    map.invalidateSize();
  }, [config]);

  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      
      (L.tileLayer as any)
        .colorFilter(
          'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
          {
            attribution: null,
            transparent: true,
            filter: filter
          }
        )
        .addTo(map);
        
        // @ts-ignore
        let circle = null;

        map.on('geosearch/showlocation', (result) => {
          // @ts-ignore
          const { location } = result;

          const { x, y } = location;
    
          // @ts-ignore
          if (circle) {
            circle.remove();
          }

          circle = L.circle([y, x], {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.05,
            radius: 10
          }).addTo(map);
        });

        
      map.locate({setView: false, enableHighAccuracy: true});
    }
  }, [theme]);

  const locateControl = new Locate({
    flyTo: true,
    keepCurrentZoomLevel: false,
    initialZoomLevel: 16.5,
    enableHighAccuracy: true,
    drawCircle: false,
    onLocationError: () => {
      setShowErrorModal(true);
      setErrorModalMessage("El usuario ha denegado el acceso a la ubicación precisa.");
    }
  });

  useEffect(() => {
    if (!easyButtonAdded) {
      locateControl.addTo(map);
      setEasyButtonAdded(true);
    }
  }, []);

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: new OpenStreetMapProvider(),
    position: 'bottomleft',
    showMarker: false,
    showPopup: false,
    notFoundMessage: 'Dirección no encontrada',
    marker: {
      icon: new L.Icon.Default(),
      draggable: false
    },
    classNames: {
    container: 'leaflet-control-geosearch form-control',
    button: '',
    resetButton: 'outline-success',
    msgbox: '',
    form: '',
    input: '',
    resultlist: '',
    item: '',
    notfound: '',
    },
    maxMarkers: 1,
    searchLabel: 'Buscar dirección...',
  });
  
  // @ts-ignore
  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return (
    <>
      <LayersControl position='topright'>
        {Object.entries(layerGroups).map(([layerName, { layer, markers }]) => (
          <LayersControl.Overlay key={layerName} name={layerName} checked>
            <LayerGroup>
              <MarkerClusterGroup
                chunkedLoading={true}
                removeOutsideVisibleBounds={true}
                animate={false}
              >
                {markers.map(marker => (
                  <Marker
                    key={marker._id}
                    position={[marker.markerLat, marker.markerLon]}
                    icon={getMarkerColor(marker.markerIconDesign || layer.layerIconDesign || '')}
                  >
                    <Popup maxWidth={280} maxHeight={380} className='leaflet-popup leaflet-popup-pane leaflet-zoom-animated'>
                        <Row className="g-9 text-body-emphasis">
                          <Col xs={12} xl={12} xxl={12} sm={12}>
                          <h4 className="mb-2 text-body-emphasis fw-semibold">{marker.markerName.toUpperCase()}</h4>
                              <Table striped bordered hover style={{ minHeight: 10, minWidth: 290}}>
                                <tbody>
                                  <tr>
                                    <td className="fw-semibold"> Capa designada: </td><td>{marker.markerLayer.layerName}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold"> Categoría(s): </td><td>{marker.markerCategory.map((categoria: CategoryRoot) => categoria.categoryTitle)}</td>
                                  </tr>
                                </tbody>
                              </Table>
                          </Col>
                        </Row>
                    </Popup>
                  </Marker>
                ))}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
        ))}
        
        <FullscreenControl forceSeparateButton={true} position="bottomright" />
      </LayersControl>      
      
      {creationMarker && (
          <Marker
            position={creationMarker}
            draggable={true}
            icon={getMarkerColor("BLACK")}
            eventHandlers={{
              dragend: (event) => {
                const latlng = event.target.getLatLng();
                setCreationMarker(latlng);
                onGeolocationChange(latlng.lat, latlng.lng);
              },
            }}
          />
      )}
      
      <Modal show={showErrorModal} onHide={() => {
        setShowErrorModal(false);
        setErrorModalMessage('');
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowErrorModal(false);
            setErrorModalMessage('');
          }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MarkerCreationMap = ({ data, onGeolocationChange, ...rest }: { data: MapMarker[], onGeolocationChange: any }) => {
  const position: LatLngExpression = [-33.537, -70.72827];
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <MapContainer
      preferCanvas={true}
      zoom={isRTL ? 10.8 : 10.5}
      minZoom={isRTL ? 1.8 : 1.3}
      zoomSnap={0.5}
      center={position}
      {...rest}
      className={"h-100 bg-body-emphasis"}
    >
    
    <LayerComponent data={data} onGeolocationChange={onGeolocationChange} />

    </MapContainer>
  );
};

export default MarkerCreationMap;
