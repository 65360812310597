import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge, { getBadgeBg, getStatusDisplay } from 'components/base/Badge';
import { MapPolygon } from 'data/polygon/polygons';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { Button } from 'react-bootstrap';
import ActionDropdownItemsPolygonEdit from 'components/common/ActionDropdownItemsPolygonEdit';

export const polygonListTableEditColumns: ColumnDef<MapPolygon>[] = [
  {
    accessorKey: 'polygonName',
    header: 'Nombre Polígono',
    cell: ({ row: { original }}) => {
      const { polygonName, _id } = original;
      return (
        polygonName
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    accessorKey: 'polygonInsertDate',
    header: 'Fecha de registro',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-2' },
      headerProps: { style: { width: '30%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Agregado por',
    accessorKey: 'polygonOwner',
    cell: ({ row: { original }}) => {
      const { polygonOwner } = original;
      return (polygonOwner && 
        <Link to="#!" className="text-decoration-none fs-12">
          { polygonOwner.firstName + " " + polygonOwner.lastName }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '20%' }, className: 'ps-1' }
    }
  },
  {
    id: 'layerStatus',
    header: 'Estado',
    accessorFn: ({ polygonStatus }) => polygonStatus,
    cell: ({ row: { original } }) => {
      const { polygonStatus } = original;
      return (
        <Badge variant="phoenix" bg={getBadgeBg(polygonStatus)}>
          {getStatusDisplay(polygonStatus)}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '20%' }, className: 'ps-8' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original }}) => (
    <RevealDropdownTrigger>
      <RevealDropdown>
        <ActionDropdownItemsPolygonEdit polygon={original} />
      </RevealDropdown>
    </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const PolygonListTableEdit = () => {
  return (
    <div className="border-bottom border-translucent">
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }}
        />
        <AdvanceTableFooter pagination className="py-3" />
      </div>
  );
};

export default PolygonListTableEdit;