import { useContext, useEffect } from 'react';
import L, { LatLngExpression } from 'leaflet';
import { MapContainer, Marker, Popup, useMap, useMapEvents, LayersControl, LayerGroup, TileLayer } from 'react-leaflet';
import { MapMarker } from 'data/puntos/puntos';
import { AppContext } from 'providers/AppProvider';

import { getMarkerColor, parseContentType } from 'helpers/utils';
import { Col, Row, Table } from 'react-bootstrap';
import "react-leaflet-fullscreen/styles.css";

/**
 * Clase usada para la preview de un marker en un mapa pequeño.
 * @see EconTopRegionsMap.tsx
 */

const LayerComponent = ({marker}: {marker: MapMarker}) => {
  const map = useMapEvents({
    click(event) {}
  });

  const { config } = useContext(AppContext);

  const { theme } = config;
  const filter =
    theme === 'dark'
      ? [
          'invert:98%',
          'grayscale:69%',
          'bright:89%',
          'contrast:111%',
          'hue:205deg',
          'saturate:1000%'
        ]
      : ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];

  useEffect(() => {
    map.invalidateSize();
  }, [config]);

  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const baseTileSet = (L.tileLayer as any)
        .colorFilter(
          'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
          {
            attribution: null,
            transparent: true,
            filter: filter
          }
        );

      baseTileSet.addTo(map);
    }
  }, [theme]);

  return (
    <>
      <LayersControl position='topright'>
        <LayersControl.BaseLayer name="XGIS" checked>
          <TileLayer
            attribution='OpenStreet Map'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Google Map">
            <TileLayer
              attribution="Google Maps"
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
        </LayersControl.BaseLayer>
        
        <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer
              attribution='OpenStreet Map'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Mapa Satelital">
            <TileLayer
              attribution='Mapa Satelital'
              url="http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
            />
        </LayersControl.BaseLayer>
            <LayerGroup>
              <Marker
                  key={marker._id}
                  position={[marker.markerLat, marker.markerLon]}
                  icon={getMarkerColor(marker.markerIconDesign || marker.markerLayer.layerIconDesign || 'BLACK')} // BLACK if layer null
              >
                <Popup maxWidth={280} maxHeight={400} className='leaflet-popup leaflet-popup-pane leaflet-zoom-animated'>
                  <Row xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                    <Col>
                      <h6 className="mb-0 text-body-emphasis fw-semibold">{marker.markerName.toUpperCase()}</h6>
                    </Col>
                  </Row>
                </Popup>
              </Marker>
            </LayerGroup>
      </LayersControl>
    </>
  );
};

const MarkerPreviewMap = ({ marker, ...rest }: { marker: MapMarker }) => {
  const position: LatLngExpression = [marker.markerLat, marker.markerLon]; // set map center to marker lat/lon
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <MapContainer
      zoom={isRTL ? 15.8 : 15.5}
      minZoom={isRTL ? 1.8 : 1.3}
      zoomSnap={0.5}
      center={position}
      {...rest}
      className="h-100 bg-body-emphasis"
    >
      <LayerComponent marker={marker}/>
    </MapContainer>
  );
};

export default MarkerPreviewMap;
