import { Col, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faPause,
  faSquare,
  faStar,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { MapMarker } from 'data/puntos/puntos';

type StatType = {
  id: number | string;
  icon: IconProp;
  title: string;
  subTitle: string;
  color: string;
};

const stats: StatType[] = [
  {
    id: 1,
    icon: faStar,
    title: '57 marcas activas',
    subTitle: 'Sin alertas',
    color: 'success'
  },
  {
    id: 2,
    icon: faPause,
    title: '5 marcas por vencer',
    subTitle: 'Prioridad',
    color: 'warning'
  },
  {
    id: 3,
    icon: faXmark,
    title: '15 fuera de fecha',
    subTitle: 'Atender de forma urgente',
    color: 'danger'
  }
];

const EcomStats = ({ markerData }: { markerData: MapMarker[]; }) => {
  const countMarkersByStatus = (status: string): number => {
    switch (status) {
      case 'activa':
        return markerData.filter(marker => marker.markerStatus === status).length;
      case 'por vencer':
        return markerData.filter(marker => {
          const currentDate = new Date();
          const expireDate = new Date(marker.markerExpireDate);
          const diffTime = expireDate.getTime() - currentDate.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return marker.markerStatus !== 'activa' && diffDays <= 3;
        }).length;
      case 'vencido':
        return markerData.filter(marker => {
          const currentDate = new Date();
          const expireDate = new Date(marker.markerExpireDate);
          return marker.markerStatus !== 'activa' && expireDate.getTime() < currentDate.getTime();
        }).length;
      default:
        return 0;
    }
  };

  const countActive = countMarkersByStatus('activa');
  const countDue = countMarkersByStatus('por vencer');
  const countExpired = countMarkersByStatus('vencido');

  const stats: StatType[] = [
    {
      id: 'active',
      icon: faStar,
      title: `${countActive} marcas activas`,
      subTitle: 'Sin alertas',
      color: 'success'
    },
    {
      id: 'due',
      icon: faPause,
      title: `${countDue} marcas por vencer`,
      subTitle: 'Prioridad',
      color: 'warning'
    },
    {
      id: 'expired',
      icon: faXmark,
      title: `${countExpired} marcas vencidas`,
      subTitle: 'Atender de forma urgente',
      color: 'danger'
    }
  ];

  return (
    <Row className="align-items-center g-4">
      {stats.map(stat => (
        <Col xs={12} md="auto" key={stat.id}>
          <Stat stat={stat} />
        </Col>
      ))}
    </Row>
  );
};

const Stat = ({ stat }: { stat: StatType }) => {
  return (
    <Stack direction="horizontal" className="align-items-center">
      {/* <img src={stat.icon} alt="" height={46} width={46} /> */}
      <span
        className="fa-layers"
        style={{ minHeight: '46px', minWidth: '46px' }}
      >
        <FontAwesomeIcon
          icon={faSquare}
          size="2x"
          className={`text-${stat.color}-light dark__text-opacity-50`}
          transform="down-4 rotate--10 left-4"
        />
        <FontAwesomeIcon
          icon={faCircle}
          size="2x"
          className={`text-stats-circle-${stat.color} fa-layers-circle`}
          transform="up-4 right-3 grow-2"
        />
        <FontAwesomeIcon
          icon={stat.icon}
          size="1x"
          className={`text-${stat.color}`}
          transform="shrink-2 up-8 right-6"
        />
      </span>

      <div className="ms-3">
        <h4 className="mb-0">{stat.title}</h4>
        <p className="text-body-secondary fs-9 mb-0">{stat.subTitle}</p>
      </div>
    </Stack>
  );
};

export default EcomStats;
