import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, ChangeEvent, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { User, fetchUsers } from 'data/users';

import MembersTable, {
  membersTableColumns
} from 'components/tables/MembersTable';

import { faFileExport, faPlus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const Members = () => {
  const [membersData, setMembersData] = useState<User[]>([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setMembersData(await fetchUsers());
    })();
  }, []);
  
  const table = useAdvanceTable({
    data: membersData,
    columns: membersTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Administración de Usuarios</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-3">
            <Row>
              <Col className="mb-3" xs={10} md={10} lg={10} xl={10} xxl={10} sm={10}>
                <SearchBox
                  placeholder="Buscar Usuarios"
                  onChange={handleSearchInputChange}
                />
              </Col>
              { /* <Col xs={7} md={3} lg={3} xl={3} xxl={4} sm={7}>
               <Button disabled variant="outline-success" className="me-2 px-2">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exportar
               </Button> 
              </Col> */ }
              <Col xs={7} md={3} lg={3} xl={3} xxl={4} sm={7}>
                <Button variant="outline-primary" onClick={handleShow} className="me-2 px-2">
                  <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                  Agregar Usuario
                </Button>
              </Col>
                <Modal size="lg" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Agregar Usuario</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Row xs={1} md={2} lg={4} xl={4} xxl={4} sm={7}>
                        <Form.Group controlId="Nombre" as={Col} >
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control type="text" placeholder="Juan" />
                        </Form.Group>
                        <Form.Group controlId="ApellidoP" as={Col} >
                          <Form.Label>Apellido P.</Form.Label>
                          <Form.Control type="text" placeholder="Apellido Paterno" />
                        </Form.Group>
                        <Form.Group controlId="ApellidoM" as={Col} >
                          <Form.Label>Apellido M.</Form.Label>
                          <Form.Control type="text" placeholder="Apellido Materno" />
                        </Form.Group>
                        <Form.Group controlId="Rut" as={Col} >
                          <Form.Label>R.U.T / DNI</Form.Label>
                          <Form.Control type="text" placeholder="11111111-1" />
                        </Form.Group>
                      </Row>
                      <Row xs={1} md={2} lg={4} xl={4} xxl={4} sm={7}>
                        <Form.Group controlId="Email" as={Col} >
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="email" placeholder="nombre@xgis.cl" autoFocus />
                        </Form.Group>
                        <Form.Group controlId="Direccion" as={Col} >
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control type="text" placeholder="Nombre calle, N°" />
                        </Form.Group>
                        <Form.Group controlId="Region" as={Col} >
                          <Form.Label>Región</Form.Label>
                          <Form.Select defaultValue="Elegir...">
                            <option>I - Tarapacá</option>
                            <option>II - Antofagasta</option>
                            <option>III - Atacama</option>
                            <option>IV - Coquimbo</option>
                            <option>V - Valparaiso</option>
                            <option>VI - O'higgins</option>
                            <option>VII - Maule</option>
                            <option>VIII - Bío Bío</option>
                            <option>IX - Araucanía</option>
                            <option>X - Los Lagos</option>
                            <option>XI - Aysén</option>
                            <option>XII - Magallanes</option>
                            <option>RM - Metropolitana</option>
                            <option>XIV - Los Ríos</option>
                            <option>XV - Arica y Parinacota</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="Comuna" as={Col} >
                          <Form.Label>Comuna</Form.Label>
                          <Form.Control type="text" placeholder="El monte" />
                        </Form.Group>
                      </Row>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                      Guardar
                    </Button>
                  </Modal.Footer>
                </Modal>
              <Col xs={7} md={3} lg={3} xl={3} xxl={4} sm={7}>
                <Button variant="outline-warning" className="me-2 px-2" onClick={() => { 
                     alert("hakos baelz is rat");
                 }}>
                  <FontAwesomeIcon icon={faXmarkCircle} className="fs-9 me-2" />
                  Eliminar Selección
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Members;