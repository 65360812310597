import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from 'helpers/utils';
import { User, fetchUsers } from 'data/users';
import { MapLayer, fetchLayers } from 'data/capas/capas';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { Link, useParams } from 'react-router-dom';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import PolygonCreationMap from 'components/leaflet-maps/PolygonCreationMap';

import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import L, { LatLng, LatLngExpression } from 'leaflet';
import { handleFormChange } from 'helpers/utils';
import { Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import CheckmarkAnimation from './modal_anim/CheckmarkAnimation';
import CrossAnimation from './modal_anim/CrossAnimation';
import PolygonListTableEdit, { polygonListTableEditColumns } from 'components/tables/PolygonListTableEdit';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const defaultFormData: Omit<MapPolygon, "_id" | "polygonOwner"> = {
  polygonName: '',
  polygonDescription: '',
  polygonLayer: undefined,
  polygonCoords: [],
  polygonStatus: 'activa', // "activa" es por default
  polygonInsertDate: new Date(),
  polygonExpireDate: new Date(),
};

const PoligonoListView = () => {
  const [userData, setUserData] = useState<User[]>([]);
  const [markerData, setMarkerData] = useState<MapMarker[]>([]);
  const [layerData, setLayerData] = useState<MapLayer[]>([]);
  const [polygonData, setPolygonData] = useState<MapPolygon[]>([]); // Cada valor incluye un conjunto de coordenadas en un array tridimensional obtenido del backend

  const [loading, setLoading] = useState(true); // Loading input layer state
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para manejar el envío de la solicitud

  const [selectedLayer, setSelectedLayer] = useState<MapLayer | null>(null);

  const [filteredPolygons, setFilteredPolygons] = useState<MapPolygon[]>([]);

  const filterPolygonsByLayer = () => {
    if (selectedLayer) {
      const filteredPolygons = polygonData.filter(polygon => {
        return polygon.polygonLayer?._id === selectedLayer._id;
      });
      setFilteredPolygons(filteredPolygons);
    } else {
      setFilteredPolygons(polygonData);
    }
  };

  useEffect(() => {
    filterPolygonsByLayer();
  }, [selectedLayer, polygonData]);

  const { id } = useParams();

  const [coordCache, setCoordCache] = useState<LatLngExpression[]>([]);
  const [undoStack, setUndoStack] = useState<LatLngExpression[][]>([]);
  const [redoStack, setRedoStack] = useState<LatLngExpression[][]>([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const table = useAdvanceTable({
    data: filteredPolygons,
    columns: polygonListTableEditColumns,
    pageSize: 6,
    pagination: true,
    sortable: true,
    selection: false
  });

  const loadData = async () => {
    setUserData(await fetchUsers());
    setLayerData(await fetchLayers());
    setMarkerData(await fetchMarkers());
    setPolygonData(await fetchPolygons());
    setLoading(false);
  };

  const onMapClick = (map: L.Map, latlng: L.LatLng) => {
    if (isSubmitting) return;

    const newCoord = [latlng.lat, latlng.lng] as LatLngExpression;

    setUndoStack([...undoStack, [...coordCache]]);
    setRedoStack([]);

    if (coordCache.length > 0) {
      const startCoord = coordCache[0] as [number, number];
      const distance = map.distance(latlng, L.latLng(startCoord[0], startCoord[1]));

      if (distance < 10) {
        setCoordCache([...coordCache, startCoord]);
      } else {
        setCoordCache([...coordCache, newCoord]);
      }
    } else {
      setCoordCache([...coordCache, newCoord]);
    }
  }

  const loadLayer = async (_id: string | undefined) => {
    if (!_id) {
      alert("No se ha detectado una capa en la solicitud.");
      return;
    }
    
    layerData.forEach(layer => {
      console.log(layer._id)
    });

    const layer = layerData.find(layer => layer._id === _id);
    if (layer) {
      setSelectedLayer(layer);
    } else {
      alert("Capa con id " + _id + " no existe en la base de datos.");
    }
  };

  const handleUndo = () => {
    if (isSubmitting) return;

    if (coordCache.length > 0) {
      setRedoStack([...redoStack, [...coordCache]]);
      setCoordCache([...undoStack.pop() || []]);
    }
  };

  const handleRedo = () => {
    if (isSubmitting) return;

    if (redoStack.length > 0) {
      setUndoStack([...undoStack, [...coordCache]]);
      setCoordCache([...redoStack.pop() || []]);
    }
  };

  // Input data
  const [formData, setFormData] = useState<Omit<MapPolygon, "_id" | "polygonOwner">>(defaultFormData);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      polygonCoords: coordCache.map(coord => JSON.stringify(coord)),
    }));
  }, [coordCache]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedLayer) return;
    formData.polygonLayer = selectedLayer;

    setIsSubmitting(true);

    axios.put(apiUrl + '/api/polygon/add', formData, { withCredentials: true })
      .then(response => {
        setShowSuccessModal(true);

        loadData(); 
      })
      .catch(error => {
        setShowErrorModal(true);
        console.error('Error al enviar la solicitud:', error);
      })
      .finally(() => {
        setFormData(defaultFormData);
        setCoordCache([]);

        setIsSubmitting(false);
      });
  };

  // page load
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      loadLayer(id);
    }
  }, [loading, id]);
  
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      ) : (
        <>
        <div className="mb-8">
          {selectedLayer && (
            <>
              <h2 className="mb-2">Polígonos capa {selectedLayer.layerName}</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Dibuje / Edite líneas y polígonos en la capa seleccionada.
              </h5>
            </>
          )}
          <div className="mb-3">  
            <Button
              as={Link}
              to="/apps/poligonos/poligonos-list-view"
              variant="phoenix-secondary"
              size="sm"
              startIcon={<FontAwesomeIcon icon={faListAlt} className="fs-10" />}
            >
              Volver a lista
            </Button>
          </div>
          {markerData && selectedLayer && (
            <>
              <Row className="mb-1 g-1">
                <Col xs={12} xl={12} xxl={12} sm={12}>
                  <div
                    style={{ height: 300, maxHeight: 320 }}
                    className="mb-3 mb-xl-4 location-map border border-translucent overflow-hidden"
                  >
                    <PolygonCreationMap
                      markerData={markerData}
                      polygonData={polygonData}
                      selectedLayer={selectedLayer}
                      coordCache={coordCache}
                      setCoordCache={setCoordCache}
                      onMapClick={onMapClick}
                    />
                  </div>
                </Col>
  
                <div className="align-items-center flex-wrap gap-x-2 gap-y-5 mb-3">
                  <Form>
                    <Form.Group>
                      <Form.Label>Nombre del polígono</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Zona de peligro"
                        name="polygonName"
                        onChange={(e) => handleFormChange(e, formData, setFormData)} // e = event
                        value={formData?.polygonName}
                        disabled={isSubmitting}
                      />
                    </Form.Group>
                  <Form.Group>
                    <Form.Label>Descripción del Polígono</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Descripción corta"
                        name="polygonDescription"
                        onChange={(e) => handleFormChange(e, formData, setFormData)} // e = event
                        value={formData?.polygonDescription}
                        disabled={isSubmitting}
                      />
                    </Form.Group>
                  </Form>
                </div>
                
                <div className="d-flex align-items-center flex-wrap gap-x-2 gap-y-5 mb-3">
                  <Button variant="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    {isSubmitting ? 'Guardando...' : 'Guardar'}
                  </Button>
                  <Button variant="danger" disabled={isSubmitting} onClick={() => { setCoordCache([]); }}>
                    {'Limpiar'}
                  </Button>
                  <Button variant="secondary" disabled={isSubmitting} onClick={() => { handleUndo(); }}>
                    {'Deshacer'}
                  </Button>
                  <Button variant="secondary" disabled={isSubmitting} onClick={() => { handleRedo(); }}>
                    {'Rehacer'}
                  </Button>
                </div>
              </Row>
            </>
          )}
        </div>
        </>
      )}
      
        <div>
          <AdvanceTableProvider {...table}>
            <PolygonListTableEdit />
          </AdvanceTableProvider>
        </div>

      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Polígono insertado correctamente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          Hubo un error al insertar el polígono. Inténtelo nuevamente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PoligonoListView;