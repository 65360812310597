import { useState, useEffect } from 'react';
import { CategoryRoot, ContentData, MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { Link, useParams } from 'react-router-dom';
import { Col, FormSelect, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  faListAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import MarkerPreviewMap from 'components/leaflet-maps/MarkerPreviewMap';
import { Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import WarningAnimation from '../poligonos/modal_anim/WarningAnimation';
import { ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { apiUrl } from 'helpers/utils';
import CrossAnimation from '../poligonos/modal_anim/CrossAnimation';
import CheckmarkAnimation from '../poligonos/modal_anim/CheckmarkAnimation';
import { useNavigate } from 'react-router-dom';
import MarkerEditMap from 'components/leaflet-maps/MarkerEditMap';

const PuntosDetallesView = () => {
  const [markerData, setMarkerData] = useState<MapMarker[]>([]);

  const [loading, setLoading] = useState(true);

  const [selectedMarker, setSelectedMarker] = useState<MapMarker | null>(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  // Edit form data
  const [formData, setFormData] = useState({
    markerName: '',
    markerDescription: '',
    markerLayer: '',
    markerAddress: '',
    markerLat: 0,
    markerLon: 0,
    markerStatus: 'activa',
    markerExpireDate: new Date(),
    publicAsset: false,
    markerCategory: [{ categoryTitle: '', categoryDesc: '' }],
    markerData: [{ contentDataType: 'text', contentDataRowTitle: '', contentDataContent: '' }],
    markerTinyMce: ''
  });

  const navigate = useNavigate();

  const { id } = useParams();

  const loadData = async () => {
    setMarkerData(await fetchMarkers());
    setLoading(false);
  };

  const loadLayer = async (_id: string | undefined) => {
    if (!_id) {
      alert("No se ha detectado una capa en la solicitud.");
      return;
    }

    markerData.forEach(marker => {
      console.log(marker._id)
    });
    
    const marker = markerData.find(marker => marker._id === _id);
    if(marker) {
      setSelectedMarker(marker);

      // Set form data
      setFormData({
        markerName: marker.markerName,
        markerDescription: marker.markerDescription,
        markerLayer: marker.markerLayer._id,
        markerAddress: marker.markerAddress,
        markerLat: marker.markerLat,
        markerLon: marker.markerLon,
        markerStatus: marker.markerStatus,
        markerExpireDate: marker.markerExpireDate,
        publicAsset: marker.publicAsset,
        markerCategory: marker.markerCategory,
        markerData: marker.markerData,
        markerTinyMce: marker.markerTinyMce
      });
    } else {
      setShowErrorModal(true);
      setErrorModalMessage("La marca con el identificador " + _id + " no existe en la base de datos.");
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowConfirmModal(true);
    setShowDeleteModal(false); // Cerrar la primera modal
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/apps/puntos/puntos-list-view');
  }


  const handleFinalDelete = async () => {
    if (!selectedMarker) return;

    setShowConfirmModal(false)

    axios.delete(apiUrl + '/api/marker/delete/' + selectedMarker._id, { withCredentials: true })
      .then(response => {
        setShowSuccessModal(true);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud para eliminar el polígono:', error);
        if (error.response && error.response.status === 404) {
          setShowErrorModal(true);
          setErrorModalMessage("La marca que intenta eliminar no existe.");
        } else {
          setShowErrorModal(true);
          setErrorModalMessage("Hubo un error al eliminar la marca seleccionada, por favor, intente más tarde.");
        }
      });
  };

  // page load
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      loadLayer(id);
    }
  }, [loading, id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdate = async () => {
    if (!selectedMarker) return;

    axios.patch(apiUrl + `/api/marker/update/${selectedMarker._id}`, formData, { withCredentials: true })
      .then(response => {
        setShowSuccessModal(true);
        loadData();
      })
      .catch(error => {
        console.error('Error al enviar la solicitud para actualizar el marcador:', error);
        setShowErrorModal(true);
        setErrorModalMessage("Hubo un error al actualizar la marca seleccionada, por favor, intente más tarde.");
      });
  };
  
  return (
    <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </div>
    ) : (
      <>
        <div className="mb-8">
        {selectedMarker && ( 
          <>
          <h2 className="mb-2">Marca en capa { selectedMarker.markerLayer.layerName }</h2>
          <h5 className="text-body-tertiary fw-semibold">
              Detalle de marca {selectedMarker.markerName} - {selectedMarker.markerLat} / {selectedMarker.markerLon}
            </h5><br />
          </> 
        )}
        <div className="mb-3">  
          <Button
            as={Link}
            to="/apps/puntos/puntos-list-view"
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faListAlt} className="fs-10" />}
          >
            Volver a lista
          </Button>
        </div>
        {markerData && selectedMarker && (
          <>
          <Row className="mb-1 g-1">
            <Col xs={12} xl={12} xxl={12} sm={12}>
              <div style={{height:300, maxHeight:320}} className="mb-3 mb-xl-4 location-map border border-translucent overflow-hidden">
                { <MarkerEditMap marker={selectedMarker} formData={formData}/> }
              </div>
            </Col>
          </Row>
          </>
        )}
        </div>

      <Row className="gy-3 mb-1 justify-content-between" xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
        <Form> { /* Formulario de datos */ }
          <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Nombre" className='position-relative'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="markerName"
                value={formData.markerName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="Descripcion">
              <Form.Label>Descripcion</Form.Label>
                <Form.Control
                  type="text"
                  name="markerDescription"
                  value={formData.markerDescription}
                  onChange={handleChange}
                />
            </Form.Group>
            
            <Form.Group as={Col} controlId="Capa">
              <Form.Label>Capa</Form.Label>
                <Form.Control
                  type="text"
                  name="markerLayer"
                  value={selectedMarker?.markerLayer.layerName}
                  disabled
                />
            </Form.Group>
            <Form.Group as={Col} controlId="Categorias">
              <Form.Label>Categorías</Form.Label>
              <Form.Control
                type="text"
                value={selectedMarker?.markerCategory.map(category => category.categoryTitle).join(', ')}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Direccion">
              <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="markerAddress"
                  value={formData.markerAddress}
                  onChange={handleChange}
                />
            </Form.Group>

            <Form.Group as={Col} controlId="Expira">
              <Form.Label>Expira</Form.Label>
              <Form.Control
                type="text"
                placeholder="Fecha de expiración"
                value={selectedMarker?.markerExpireDate.toString()}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="markerStatus" as={Col}>
              <Form.Label>Estado</Form.Label>
              <FormSelect
                value={formData.markerStatus}
                name="markerStatus"
                onChange={handleChange}
              >
                <option value="activa">Activa</option>
                <option value="inactiva">Inactiva</option>
              </FormSelect>
            </Form.Group>

            <Form.Group as={Col} controlId="Icono">
              <Form.Label>Icono</Form.Label>
              <Form.Control placeholder="Icono por defecto" disabled />
            </Form.Group>
          
            <Form.Group as={Col} controlId="Latitud">
              <Form.Label>Latitud</Form.Label>
              <Form.Control type="text" value={formData.markerLat} disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="Longitud">
              <Form.Label>Longitud</Form.Label>
              <Form.Control type="text" value={formData.markerLon} disabled />
            </Form.Group>
          </Row>
          {(selectedMarker && selectedMarker.markerTinyMce && selectedMarker.markerTinyMce !== '' &&
            <Row className="mb-3 g-1" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
              <Form.Group as={Col} controlId="Notas">
                <Form.Label>Notas / Enlaces</Form.Label>
                  <div dangerouslySetInnerHTML={{ __html: selectedMarker.markerTinyMce }}></div>
              </Form.Group>
            </Row>
          )}
              <ButtonGroup>
                <Button
                  variant="phoenix-success"
                  size="sm"
                  onClick={handleUpdate}
                >
                  Actualizar
                </Button>
                <Button
                  variant="phoenix-danger"
                  size="sm"
                  onClick={handleDeleteClick}
                >
                  Eliminar
                </Button>
              </ButtonGroup>
        </Form>
      </Row>
      </>
    )}

      <Modal show={showDeleteModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>¿Está seguro de que desea eliminar?</p>
          <ButtonGroup vertical style={{ marginTop: '10px' }}>
            <Button variant="secondary" onClick={handleCancelDelete}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete} style={{ marginTop: '10px' }}>
              Confirmar
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <WarningAnimation/>
        <Modal.Body className="text-center">
          <p>¿Está completamente seguro de que desea eliminar?</p><br></br>
          <p>¡¡ESTA ACCIÓN ES IRREVERSIBLE!!</p>
          <ButtonGroup vertical style={{ marginTop: '10px' }}>
            <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleFinalDelete} style={{ marginTop: '10px' }}>
              Confirmar
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Marca alterada con éxito
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PuntosDetallesView;