import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Capa } from 'data/capas/capas';
import Avatar from 'components/base/Avatar';
import { ProgressBar } from 'react-bootstrap';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';

import Badge, { getBadgeBg, getStatusDisplay } from 'components/base/Badge';
import { MapLayer } from 'data/capas/capas'
import testAvatar from 'assets/img/testing/avatar_default.jpg';
import ActionDropdownItemsLayerEdit from 'components/common/ActionDropdownItemsLayerEdit';
import ActionDropdownItemsPolygonEdit from 'components/common/ActionDropdownItemsPolygonEdit';

export const layerListTableColumns: ColumnDef<MapLayer>[] = [
  {
    accessorKey: 'layerName',
    header: 'Identificador',
    cell: ({ row: { original }}) => {
      const { layerName, _id } = original;
      return (
        <Link to={ "../capas-detalles/" + _id } className="text-decoration-none fs-12">
          { layerName.toUpperCase() }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '25%' } }
    }
  },
  {
    id: 'layerAssignments',
    accessorKey: 'layerAssignments',
    header: 'Asignaciones',
    cell: ({ row: { original } }) => {
      const { layerAssignations } = original;
      return (
        <Avatar.Group total={layerAssignations.length} size="s">
          {layerAssignations.slice(0, 4).map(assigne => (
            <Avatar
              key={assigne._id}
              src={testAvatar}
              variant={testAvatar ? 'image' : 'name'}
              size="s"
            >
              {!testAvatar && assigne.username[0]}
            </Avatar>
          ))}
        </Avatar.Group>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    accessorKey: 'layerInsertDate',
    header: 'Fecha de registro',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-2' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Agregada por',
    accessorKey: 'layerOwner',
    cell: ({ row: { original }}) => {
      const { layerOwner } = original;
      return (
        <Link to="#!" className="text-decoration-none fs-12">
          { layerOwner.firstName + " " + layerOwner.lastName }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    header: 'SKU',
    accessorKey: 'layerSku',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    id: 'layerMarkerLimit',
    header: 'Registros',
    cell: ({ row: { original } }) => {
      const { layerMarkerLimit } = original;

      return (layerMarkerLimit);
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '15%' }, className: 'ps-3' }
    }
  },
  {
    id: 'layerStatus',
    header: 'Estado',
    accessorFn: ({ layerStatus }) => layerStatus,
    cell: ({ row: { original } }) => {
      const { layerStatus } = original;
      return (
        <Badge variant="phoenix" bg={getBadgeBg(layerStatus)}>
          {getStatusDisplay(layerStatus)}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original }}) => (
    <RevealDropdownTrigger>
      <RevealDropdown>
        <ActionDropdownItemsLayerEdit layer={original} />
      </RevealDropdown>
    </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

/*
export const capasListTableColumns: ColumnDef<Capa>[] = [
  {
    accessorKey: 'name',
    header: 'Nombre capa',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to="#" className="text-decoration-none fs-12">
          {name.toUpperCase()}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '25%' } }
    }
  },
  {
    id: 'assigness',
    header: 'Asignaciones',
    cell: ({ row: { original } }) => {
      const { assigness } = original;
      return (
        <Avatar.Group total={assigness.length} size="s">
          {assigness.slice(0, 4).map(assigne => (
            <Avatar
              key={assigne.id}
              src={assigne.avatar ? assigne.avatar : undefined}
              variant={assigne.avatar ? 'image' : 'name'}
              size="s"
            >
              {!assigne.avatar && assigne.name[0]}
            </Avatar>
          ))}
        </Avatar.Group>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Fecha Registro',
    accessorKey: 'start',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-2' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Owner',
    accessorKey: 'deadline',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    accessorKey: 'task',
    header: 'COD',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    id: 'progress',
    header: 'Registros',
    cell: ({ row: { original } }) => {
      const { progress } = original;

      return (
        <>
          <p className="text-body-secondary fs-10 mb-0">
            {progress.min} / {progress.max}
          </p>
          <ProgressBar
            now={(progress.min / progress.max) * 100}
            style={{ height: 3 }}
            variant="success"
          />
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '15%' }, className: 'ps-3' }
    }
  },
  {
    id: 'status',
    header: 'Estado',
    accessorFn: ({ status }) => status.label,
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge variant="phoenix" bg={status.type}>
          {status.label}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];
*/
const LayerListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default LayerListTable;
