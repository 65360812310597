import { faCircleInfo, faComment, faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useChatWidgetContext } from 'providers/ChatWidgetProvider';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

const FaqCta = () => {
  const { setIsOpenChat, isOpenChat } = useChatWidgetContext();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <div>
      <div className="text-center py-11">
        <h3 className="text-body-emphasis">¿Te falta algo?</h3>
        <p className="text-body">Visita nuestro catálogo y potencia tu plataforma!</p>
        <Button
          /* onClick={() => setIsOpenChat(!isOpenChat)} */
          onClick={handleShow}
          variant="outline-primary"
          size="sm"
          startIcon={<FontAwesomeIcon icon={faShop} className="me-2" />}
        >
          Catálogo e-Shop
        </Button>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>e-Shop</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant={'subtle-warning'} className="d-flex align-items-center">
              <FontAwesomeIcon icon={faCircleInfo} className="text-warning fs-5 me-3" />
                Aún trabajamos en esto. Pronto lo conseguiremos {'<3'}
            </Alert>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default FaqCta;
