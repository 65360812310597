import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon,UilChartPie,UilCube } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Resumen informativo', // Nombre de categoría
        icon: 'pie-chart',
        active: true,
        path: '/',
        pathName: 'default-dashboard',
        flat: true
      },
      {
        name: 'Mapa & Marcas', 
        icon: 'compass',
        active: true,
        path: '/apps/mapa/mainmap',
        pathName: 'default-dashboard',
        flat: true
      }
    ]
  },
  {
    label: 'Usuarios y Perfiles',
    icon: UilCube,
    pages: [
      {
        name: 'Administración de usuarios',
        icon: 'user',
        path: '/pages/members',
        pathName: 'administrator',
        active: true
      },
   /*   {
        name: 'Administración de roles',
        icon: 'user-plus',
        path: '/pages/coming-soon',
        pathName: 'administrator',
        active: true
      } */
    ]
  },
  /*
{
        name: 'email',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'inbox',
            path: '/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'email-detail',
            path: '/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'compose',
            path: '/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
  */
  {
    label: 'Capas de georreferencias',
    icon: UilCube,
    pages: [
      {
        name: 'Administración de capas',
        icon: 'map',
        path: '/apps/capas/capas-list-view',
        pathName: 'administrator',
        active: true
      },
      {
        name: 'Registro de polígonos',
        icon: 'trello',
        path: '/apps/poligonos/poligonos-list-view',
        pathName: 'administrator',
        active: true,
        new: false
      },
      {
        name: 'Administración de marcas',
        icon: 'eye',
        path: '/apps/puntos/puntos-list-view',
        pathName: 'administrator',
        active: true
      },
      {
        name: 'Registro de marcas',
        icon: 'map-pin',
        path: '/apps/puntos/registro',
        pathName: 'administrator',
        active: true
      },
    ]
  },
  {
    label: 'Configuración',
    icon: UilCube,
    pages: [
      {
        name: 'General',
        icon: 'settings',
        active: true,
        pages: [
          /*{
            name: 'inbox',
            path: '/apps/email/inbox',
            pathName: 'email-inbox',
            active: false
          },
          {
            name: 'email-detail',
            path: '/apps/email/email-detail',
            pathName: 'email-detail',
            active: false
          }, */
          {
            name: 'Bitácora',
            path: '/configuracion/logs/log-list',
            pathName: 'email-compose',
            active: true
          }
        ]
      }, /*
      {
        name: 'Ajustes Sistema',
        icon: 'tool',
        path: '#',
        pathName: 'administrator',
        active: false
      },*/
      {
        name: 'Sobre XGIS',
        icon: 'git-merge',
        path: 'changelog',
        pathName: 'configuration',
        active: true,
      },
      {
        name: 'Timeline',
        icon: 'clock',
        path: '/pages/timeline',
        pathName: 'timeline-page',
        active: true,
        new: true
      }
    ]
  }, /*
  {
    label: 'Información',
    icon: UilCube,
    pages: [
      {
        name: 'Centro de ayuda',
        icon: 'life-buoy',
        path: '/pages/coming-soon',
        pathName: 'administrator',
        active: true
      } /*
      {
        name: 'Licencia Software',
        icon: 'help-circle',
        path: '/pages/coming-soon',
        pathName: 'administrator',
        active: true
      }
    ]
  }, */
];
