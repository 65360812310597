import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { MapLayer } from 'data/capas/capas';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface CapasTopSectionInterface {
  activeView: 'list' | 'board' | 'card';
}

const CapasTopSection = ({ activeView }: CapasTopSectionInterface) => {
  const navigate = useNavigate();
  const { setGlobalFilter, getPrePaginationRowModel, getColumn } =
    useAdvanceTableContext<MapLayer>();
  
  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) => {
      return getPrePaginationRowModel().rows.filter(({ original }) => {
        const layerStatus = original.layerStatus;
        return layerStatus === label || (!layerStatus && label === 'all');
      }).length;
    };
    
    return [
      {
        label: 'Todas',
        value: 'all',
        onClick: () => handleFilterItemClick('layerStatus', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: 'Limpia',
        value: 'limpia',
        onClick: () => handleFilterItemClick('layerStatus', 'limpia'), 
        count: getDataCount('limpia')
      },
      {
        label: 'Registros',
        value: 'registros',
        onClick: () => handleFilterItemClick('layerStatus', 'registros'),
        count: getDataCount('registros')
      }
    ];
  }, [getPrePaginationRowModel()]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Row className="g-3 justify-content-between align-items-center mb-4">
      <Col xs={12} sm="auto">
        <FilterTab tabItems={tabItems} />
      </Col>
      <Col xs={12} sm="auto">
        <div className="d-flex align-items-center gap-1">
          <SearchBox
            onChange={handleSearchInputChange}
            placeholder="Buscar en capas"
            style={{ maxWidth: '30rem' }}
            className="me-3"
          />
        </div>
      </Col>
    </Row>
  );
};

export default CapasTopSection;
