import React, { useState } from 'react';
import { Modal, Button, Table, Row, Col } from 'react-bootstrap';
import MarkerPreviewMap from 'components/leaflet-maps/MarkerPreviewMap';
import { parseContentType, apiUrl } from 'helpers/utils';
import { MapLayer } from 'data/capas/capas';
import { ContentData, MapMarker } from 'data/puntos/puntos';

interface MarkerContent {
    id: number;
    contentData: ContentData[];
    isOpen: boolean;
}

interface ShowModalProps {
    show: boolean;
    handleClose: () => void;
    selectedData?: {
        layer: MapLayer;
        marker: MapMarker;
    };
    markerContent: MarkerContent[];
}

const MarkerDetailModal: React.FC<ShowModalProps> = ({ show, handleClose, selectedData, markerContent }) => {
    const [showAllTables, setShowAllTables] = useState(false);

    const toggleAllTables = () => {
        setShowAllTables(!showAllTables);
    };

    return (
        <Modal maxWidth={400} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles Avanzados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="gx-1 border-y">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                        <div className="h-100 w-100" style={{ minHeight: 150 }}>
                            {selectedData && selectedData.marker && (
                                <>
                                    <MarkerPreviewMap marker={selectedData.marker} />
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                <Table striped bordered hover style={{ minHeight: 1, minWidth: 200, fontSize: 14 }}>
                    <tbody style={{ height: 12 }}>
                        {selectedData && selectedData.layer && selectedData.marker && (
                            <>
                                <tr style={{ fontSize: 12 }}>
                                    <td style={{ minWidth: 1, maxHeight: 1000 }} className="fw-semibold"> Marcador: </td><td>{selectedData.marker.markerName}</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold"> Descripción: </td><td>{selectedData.marker.markerDescription}</td>
                                </tr>
                                <tr>
                                    <td className="fw-semibold"> Capa: </td><td>{selectedData.layer.layerName}</td>
                                </tr>

                                {selectedData && selectedData.marker.markerTinyMce && selectedData.marker.markerTinyMce !== '' && (
                                    <tr>
                                        <td style={{ minWidth: 1 }} className="fw-semibold"> Notas: </td><td dangerouslySetInnerHTML={{ __html: selectedData?.marker.markerTinyMce }}></td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </Table>
                <div>
                    <Button variant="outline-warning" onClick={toggleAllTables} style={{ marginBottom: '10px' }}>
                        {showAllTables ? "Cerrar datos avanzados" : "Mostrar datos avanzados"}
                    </Button>
                    <br />
                    <Table striped bordered hover style={{ minHeight: 10, minWidth: 290 }}>
                        <tbody style={{ fontSize: 14 }}>
                            {markerContent.map(entry => (
                                <>
                                    {showAllTables && (
                                        <>
                                            {entry.contentData.map((item, index) => (
                                                <>
                                                    {item.contentDataType === 'text' && (
                                                        <tr key={index} style={{ fontSize: 12 }}>
                                                            <td style={{ minWidth: 1 }} className="fw-semibold">{item.contentDataRowTitle}</td>
                                                            <td style={{ minWidth: 200 }} dangerouslySetInnerHTML={{ __html: parseContentType(item) }}></td>
                                                        </tr>
                                                    )}

                                                    {item.contentDataType === 'textarea' && (
                                                        <tr key={index} style={{ fontSize: 12 }}>
                                                            <td style={{ minWidth: 1 }} className="fw-semibold">{item.contentDataRowTitle}</td>
                                                            <td style={{ minWidth: 200 }} dangerouslySetInnerHTML={{ __html: parseContentType(item) }}></td>
                                                        </tr>
                                                    )}

                                                    {item.contentDataType === 'file' && (
                                                        <>
                                                            <tr key={index} style={{ fontSize: 12 }}>
                                                                <td style={{ minWidth: 1 }} className="fw-semibold">{"Archivo(s) subido(s)"}</td>
                                                                <td style={{ minWidth: 200 }}>
                                                                    {item.contentFileNames.map((fileName, idx) => (
                                                                        <div key={idx}>
                                                                            <a href={`${apiUrl}/api/uploads/${fileName}`} target="_blank" rel="noopener noreferrer">{fileName}</a>
                                                                            <br />
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MarkerDetailModal;