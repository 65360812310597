import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import migrations from 'data/migrations';
import { Alert, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Migrations = () => {
  return (
    <div>
      <h2 className="mb-4 lh-sm">Actualizaciones / Migraciones</h2>

      <Card className="mb-4">
        <Card.Body>
          <Alert variant="subtle-warning" className="mb-0">
            <div className="d-flex">
              <FontAwesomeIcon icon={faExclamationTriangle} className="fs-5" />
              <div className="ms-3 flex-1">
                <h4 className="alert-heading">Antes de actualizar/migrar!</h4>
                ¡Antes de actualizar!
                Haga una copia de seguridad de sus archivos y lea el registro de cambios antes de actualizar o migrar sus datos. Si tiene algún problema con la plataforma durante la exportación/importación, 
                no dude en contactarnos a <a href="mailto:contacto@linuxcorp.cl">contacto@linuxcorp.cl</a>.{' '}
              </div>
            </div>
          </Alert>
        </Card.Body>
        <Link to={'../changelog'}> <Button size={'sm'} variant="outline-info" className="me-2 px-2">Volver</Button></Link>
      </Card>

      {migrations.map(migration => (
        <PhoenixDocCard className="mb-4" key={migration.from}>
          <PhoenixDocCard.Header noPreview>
            <div className="d-flex align-items-center">
              <p className="text-body text-nowrap mb-0">
                <code className="fs-7 opacity-50">{migration.from}</code>
                <span className="mx-3">a</span>
                <code className="fs-7">{migration.to}</code>
              </p>
              <Link
                to={`#${migration.to}`}
                className="opacity-0 hover-show fw-bold ps-2"
              >
                #
              </Link>
            </div>
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body>
            <ul className="bullet-inside ps-0">
              <li>
                <h6 className="d-inline-block">Añadido</h6>
                <ul>
                  {migration.new.map(file => (
                    <li key={file}>{file}</li>
                  ))}
                </ul>
              </li>
              <li>
                <h6 className="d-inline-block">Modificado</h6>
                <ul>
                  {migration.update.map(file => (
                    <li key={file}>{file}</li>
                  ))}
                </ul>
              </li>
              {migration.delete && (
                <li>
                  <h6 className="d-inline-block">Removido</h6>
                  <ul>
                    {migration.delete.map(file => (
                      <li key={file}>{file}</li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>
      ))}
    </div>
  );
};

export default Migrations;
