import changelogListElips from './changelogList';

export default [
  {
    version: process.env.REACT_APP_VERSION,
    title: process.env.REACT_APP_TITLE,
    publishDate: process.env.REACT_APP_STARTDATE,
    alertText:
      'Esta es la última actualización de la plataforma. Por favor lea atentamente la documentación asociada y términos & condiciones de uso.',
     logs: {
      new: [
        'Aplicación modo nocturno.',
        'DOC : Color de páginas'
        /* `Clases de utilidad ${changelogListElips}` */
      ],
      update: [
        'Bootstrap a versión 5.3.2',
        'Todos los archivos SCSS han sido actualizados',
        'DOC: Documentación de styles',
        'DOC: Documentación modo nocturno',
        'HTML: <code>bg-soft-{color}</code> a <code>bg-{color}-subtle</code>',
        'HTML: <code>z-index-{values}</code> a <code>z-{values}</code>',
        'HTML: <code>text-none</code> a <code>text-transform-none</code>',
        'CARACTERISTICA: <code>manual-polygon</code> a <code>auto-polygon</code>' 
       ],
      fix: ['Bugs de diseño JS']
    }
  },
  {
    version: '1.2.0',
    title: 'Alicia',
    publishDate: '15 Jun, 2023',
    logs: {
      new: [
        'PAGINA : <code>Pages/Faq/Faq-Tab</code>',
        'PAGINA : <code>Pages/Timeline</code>',
        'PAGINA : <code>Pages/Coming-Soon</code>',
        'COMPONENTE : <code>Calendar</code>',
        'CARACTERISTICA : <code>Chat Demo (widget flotante)</code>',
        'CARACTERISTICA : <code>Chat con imagen adjunta</code>'
      ],
      update: [
        'PACKAGE: typescript a v5.2.2',
        'PACKAGE: swiper a v11.0.3'
      ],
      fix: ['Bugs Menores']
    }
  },
  {
    version: '1.1.0',
    title: 'Cleopatra',
    publishDate: '12 Mar, 2021',
    logs: {
      new: [
        'Dashboard : <code>Gráficos estadísticos</code>',
        'PAGINA : <code>Edición nueva tab</code>',
        'PAGINA : <code>Filtros capas</code>',
        'COMPONENTE : <code>Formulario solicitud polígonos</code>',
        'COMPONENTE : <code>Color fondo sector polígonos</code>'
      ],
      update: [
        'Migrado desde Font Awesome React  <code>Global Use</code> a <code>Individual Use</code> para la gestión de iconos.',
        'Añadido <code>footerProps</code> en <code>AdvanceTable</code> definición de columnas.',
        'Ejemplos añadido para todo <code>Icon</code> seteado.'
      ],
      fix: ['Bugs Menores']
    }
  }
];
