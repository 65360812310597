import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-body-highlight">Olvidaste tu contraseña?</h4>
        <p className="text-body-tertiary mb-5">
          Ingresa tu correo electrónico registrado y en unos segundos te enviaremos <br className="d-sm-none" />
          un enlace para reiniciar tu contraseña
        </p>
        <Form className="d-flex align-items-center mb-5">
          <Form.Control
            type="email"
            id="email"
            className="flex-1"
            placeholder="E-Mail"
          />
          <Button
            variant="primary"
            className="ms-2"
            endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
          >
            Enviar
          </Button>
        </Form>
        <Link to="#!" className="fs-9 fw-bold">
          Aún estás teniendo inconvenientes?
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
