export default [
  {
    from: 'v1.1.0',
    to: 'v2.0.0',
    new: [
      'src/components/modules/poligono/PoligonoTopSection.tsx',
      'src/components/tables/CapasListTablePoligono.tsx',
      'src/pages/apps/poligonos/PoligonosListView.tsx',
      'src/pages/apps/poligonos/PoligonosListEdit.tsx'
    ],
    update: [
      'src/assets/scss/theme/_carousel.scss',
      'src/assets/scss/theme/_chat.scss',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_ecommerce.scss',
      'src/components/base/Dropzone.tsx',
      'src/components/base/Swiper.tsx',
      'src/components/base/Timeline.tsx',
      'src/components/cards/EventTitleCard.tsx',
      'src/components/cards/SocialProfileCard.tsx',
      'package.json'
    ]
  },
  {
    from: 'v1.0.0',
    to: 'v1.1.0',
    new: [
      'src/assets/img/bg/10-dark.png',
      'src/assets/img/bg/40.png',
      'src/assets/img/bg/8-dark.png',
      'src/assets/img/bg/9-dark.png',
      'src/assets/img/bg/bg-11-dark.png',
      'src/assets/img/spot-illustrations/bag-2-dark.png',
      'src/assets/img/spot-illustrations/rocket-dark.png',
      'src/assets/img/spot-illustrations/shield-2-dark.png',
      'src/assets/img/spot-illustrations/star-dark.png',
      'src/components/base/Unicon.tsx',
      'src/components/cards/AboutLeadCard.tsx',
      'src/data/icons/*.ts',
      'src/data/changelog.ts',
      'src/data/migration.ts'
    ],
    update: [
      '.eslintignore',
      '.eslintrc.json',
      'package.json',
      'src/Routes.tsx',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_offcanvas.scss',
      'src/assets/scss/theme/_pricing.scss',
      'src/assets/scss/theme/_progress.scss',
      'src/assets/scss/theme/_table.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/data/chat.ts',
      'src/data/email.tsx'
    ],
    delete: [
      'src/helpers/initFA.ts',
      'src/layouts/SimpleAuthLayout.tsx',
      'src/pages/modules/components/FontAwesome.tsx',
      'src/pages/modules/components/Unicons.tsx'
    ]
  }
];
