import axios from 'axios';
import TinymceEditor from 'components/base/TinymceEditor';
import MarkerCreationMap from 'components/leaflet-maps/MarkerCreationMap';
import { LayerContentData, MapLayer, fetchLayers } from 'data/capas/capas';
import { CategoryRoot, ContentData, MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { allColorOptions, apiUrl } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { Col, Row, Form, Button } from 'react-bootstrap';
import ReactSelect from 'react-select';
import CheckmarkAnimation from '../poligonos/modal_anim/CheckmarkAnimation';
import CrossAnimation from '../poligonos/modal_anim/CrossAnimation';
import Dropzone from 'components/base/Dropzone';

export interface MarkerCreationMapProps {
  formGeolocationData: {
    markerLat: number;
    markerLon: number;
  }
};

const MarkerRegisterFormComponent = ({ layerData, formGeolocationData }: { layerData: MapLayer[], formGeolocationData: MarkerCreationMapProps }) => {
  const [showForm, setShowForm] = useState(false); // Content form
  const [validated, setValidated] = useState(false);

  const [loading, setLoading] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [selectedLayerCategories, setSelectedLayerCategories] = useState<CategoryRoot[]>([]);

  const [markerDataContents, setMarkerDataContents] = useState<ContentData[]>([]);

  const [markerTinyMceContent, setMarkerTinyMceContent] = useState('');

  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  const selectRef = useRef<any>(null); // Category select
  const selectLayerRef = useRef<any>(null); // Layer select
  const iconSelectRef = useRef<any>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [formData, setFormData] = useState<{
    markerName: string;
    markerDescription: string;
    markerLayer: string;
    markerAddress: string;
    markerLat: number;
    markerLon: number;
    markerStatus: string;
    markerExpireDate: Date;
    markerIconDesign: string;
    publicAsset: boolean;
    markerCategory: CategoryRoot[]
    markerData: ContentData[],  
    markerTinyMce: string;
  }>({
    markerName: '',
    markerDescription: '',
    markerLayer: '',
    markerAddress: '',
    markerLat: 0,
    markerLon: 0,
    markerStatus: 'activa',
    markerExpireDate: new Date(),
    markerIconDesign: 'BLUE',
    publicAsset: false,
    markerCategory: [
      {
        categoryTitle: '',
        categoryDesc: ''
      }
    ],
    markerData: [{
        contentDataType: 'text',
        contentDataRowTitle: '',
        contentDataContent: '',
        contentFileNames: []
    }],
    markerTinyMce: ''
  });
  
  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleStatusChange = (e: any) => {
    const { value } = e.target;

    setFormData(prevState => ({
      ...prevState,
      markerStatus: value
    }));
  };
  
  const handleLayerChange = (e: any) => {
    const selectedLayer: MapLayer = JSON.parse(e.value);

    setSelectedLayerCategories([]);

    if (selectRef.current) {
      selectRef.current.setValue([]);
    }

    setSelectedLayerCategories(selectedLayer.layerTemplateCategory);

    setFormData(prevState => ({
      ...prevState,
      markerLayer: selectedLayer._id, // Usar _id o tendremos un error en el backend
      markerIconDesign: selectedLayer.layerIconDesign
    }));

    const newMarkerDataContents: ContentData[] = selectedLayer.layerTemplateContent
    .filter((contentData: LayerContentData) => contentData.contentDataRowTitle/* && contentData.contentDataContent*/)
    .map((contentData: LayerContentData) => ({
      contentDataType: contentData.contentDataType,
      contentDataRowTitle: contentData.contentDataRowTitle,
      contentDataContent: contentData.contentDataContent,
      contentFileNames: contentData.contentFileNames
    }));
    
    setMarkerDataContents(newMarkerDataContents);

    if (newMarkerDataContents.length > 0) {
      setShowForm(true);
      return;
    }

    setShowForm(false);
  };

  const handleExpirationDateChange = (e: any) => {
    const { value } = e.target;
    const dateObj = new Date(value);
  
    if (!isNaN(dateObj.getTime())) {
      setFormData(prevState => ({
        ...prevState,
        markerExpireDate: dateObj
      }));
    }
  };

  const handleCategoryAssignChange = (selectedOptions: any) => {
    const selectedCategoriesAssign = selectedOptions.map((option: any) => option.value);

    setFormData(prevState => ({
      ...prevState,
      markerCategory: selectedCategoriesAssign
    }));
  }

  // Content
  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
  
    const updatedContent = { ...markerDataContents[index], [name]: value };
  
    const updatedContents = [
      ...markerDataContents.slice(0, index),
      updatedContent,
      ...markerDataContents.slice(index + 1)
    ];
  
    setMarkerDataContents(updatedContents);
  };

  // TinyMCE
  const handleTinyMceChange = (value: string) => {
    setMarkerTinyMceContent(value);
  };

  const debugForm = () => {
    formData.markerLat = formGeolocationData.formGeolocationData.markerLat;
    formData.markerLon = formGeolocationData.formGeolocationData.markerLon;

    formData.markerData = markerDataContents;

    formData.markerTinyMce = markerTinyMceContent;

    console.log(JSON.stringify(formData));

    console.log(uploadFiles)
  }

  const handleSave = async (event: any) => { // event = button
    event.preventDefault();

    const form = formRef.current;
    if (form === null) {
      return;
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    const mandatoryFields = [
      formData.markerName,
      formData.markerDescription,
      formData.markerLayer,
      formData.markerAddress,
      formData.markerExpireDate,
      formData.markerCategory.length > 0,
      formGeolocationData.formGeolocationData.markerLat !== 0,
      formGeolocationData.formGeolocationData.markerLon !== 0,
      //markerDataContents.every(content => content.contentDataContent.trim() !== '' && content.contentDataRowTitle.trim() !== '')
    ];
  
    if (mandatoryFields.some(field => !field)) {
      setErrorModalMessage("Por favor rellene todos los campos de la marca");
      setShowErrorModal(true);
      return;
    }

    formData.markerLat = formGeolocationData.formGeolocationData.markerLat;
    formData.markerLon = formGeolocationData.formGeolocationData.markerLon;

    formData.markerData = markerDataContents;

    formData.markerTinyMce = markerTinyMceContent;

    if (!formData.markerName || !formData.markerDescription || formData.markerLat === 0 || formData.markerLon === 0) {
      setErrorModalMessage("Por favor rellene todos los campos de la marca");
      setShowErrorModal(true);
      return;
    } 

    setLoading(true);

    // Upload files, if any
    const uplForm = new FormData();
    uploadFiles.forEach(file => {
      uplForm.append('files', file);
    });

    await axios.post(apiUrl + '/api/upload', uplForm, {
      headers: {
          'Content-Type': 'multipart/form-data',
      },
      withCredentials: true
    })
    .then(response => {
      console.log('Respuesta del backend:', JSON.stringify(response.data));

      formData.markerData.forEach(markerData => {
        if (markerData.contentDataType === 'file') {
          response.data.files.forEach((fileName: string) => {
            markerData.contentFileNames.push(fileName);
            console.log("setting: " + markerData.contentFileNames);
          });
        }
      });
    })
    .catch(err => {
      console.error('Error al enviar la solicitud:', err);  
      setErrorModalMessage("Ha ocurrido un error al subir los archivos al servidor");
      setShowErrorModal(true);

      setLoading(false);
    });

    await axios.put(apiUrl + '/api/marker/add', formData, { withCredentials: true })
      .then(response => {
        setShowSuccessModal(true);

        //loadData(); 
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);  
        setErrorModalMessage("Ha ocurrido un error en nuestros servicios de ingreso de marcas, intente más tarde");
        setShowErrorModal(true);
      })
      .finally(() => {
        setFormData({
          markerName: '',
          markerDescription: '',
          markerLayer: '',
          markerAddress: '',
          markerLat: 0,
          markerLon: 0,
          markerStatus: 'activa',
          markerExpireDate: new Date(),
          markerIconDesign: 'BLUE',
          publicAsset: false,
          markerCategory: [
            {
              categoryTitle: '',
              categoryDesc: ''
            }
          ],
          markerData: [{
              contentDataType: 'text',
              contentDataRowTitle: '',
              contentDataContent: '',
              contentFileNames: []
          }],
          markerTinyMce: ''
        });

        /*setFormGeolocationData({
          formGeolocationData: {
            markerLat: 0,
            markerLon: 0
          }
        });*/

        // Reiniciar el valor del Select
        if (selectRef.current) {
          selectRef.current.setValue([]);
        }

        // Reiniciar layer select
        if (selectLayerRef.current) {
          selectLayerRef.current.value = 'Seleccionar capa';
        }

        setSelectedLayerCategories([]);
        setMarkerDataContents([]);
        setShowForm(false);

        // TinyMCE
        setMarkerTinyMceContent('');

        setLoading(false);
      });
  }

  const handlePublicAssetChange = (e: any) => {
    const { value } = e.target;

    console.log(value);

    setFormData(prevState => ({
      ...prevState,
      publicAsset: e.target.value === 'true'
    }));
  }

  const [statusOptions] = useState([
    { value: 'activa', label: 'Activa' },
    { value: 'deshabilitada', label: 'Inactiva' },
  ]);

  return (
    <> 
      <Row className="gy-3 mb-1 justify-content-between" xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
        <Form noValidate validated={validated} ref={formRef}> { /* Formulario de datos */ }
          <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Nombre" className='position-relative'>
              <Form.Label>Nombre</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Escuela pública"
                  name="markerName"
                  value={formData.markerName}
                  onChange={handleChange}
                  disabled={loading}
                  required
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Porfavor proporcione un nombre.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="Descripcion">
              <Form.Label>Descripcion</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Establecimiento con riesgo"
                  name="markerDescription"
                  value={formData.markerDescription}
                  onChange={handleChange}
                  disabled={loading}
                  required
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Porfavor proporcione una descripción.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            
            <Form.Group controlId="Capa" as={Col}>
              <Form.Label>Capa</Form.Label>
                <ReactSelect className="react-select-container" classNamePrefix="react-select" 
                  ref={selectLayerRef}
                  options={layerData.map(layer => ({
                    value: JSON.stringify(layer),
                    label: layer.layerName
                  }))}
                  noOptionsMessage={() => "No hay registros"}
                  placeholder="Seleccione una capa"
                  isDisabled={loading}
                  onChange={handleLayerChange} 
                  required
                >
                </ReactSelect>
                <Form.Control.Feedback type="invalid" tooltip>
                  Por favor seleccione una capa
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="Categorias" as={Col} >
              <Form.Label>Categoría(s):</Form.Label>
              <ReactSelect className="react-select-container" classNamePrefix="react-select" 
                ref={selectRef}
                options={selectedLayerCategories.map(category => ({
                  value: category,
                  label: category.categoryTitle
                }))}
                isMulti
                noOptionsMessage={() => "No hay más categorías"}
                placeholder="Seleccione categorías"
                isDisabled={loading}
                onChange={handleCategoryAssignChange}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Direccion">
              <Form.Label>Dirección</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder="Calle, número, ciudad/provincia, región"
                  name="markerAddress"
                  value={formData.markerAddress}
                  onChange={handleChange}
                  disabled={loading}
                  required
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Porfavor proporcione una dirección.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} controlId="Expira">
              <Form.Label>Expira</Form.Label>
              <Form.Control
                type="date"
                placeholder="Fecha de expiración"
                disabled={loading}
                value={formData.markerExpireDate.toISOString().split('T')[0]}
                onChange={handleExpirationDateChange} 
              />
            </Form.Group>
            
            <Form.Group controlId="markerStatus" as={Col} >
              <Form.Label>Estado</Form.Label>
              <Form.Select
                value={formData.markerStatus}
                name="markerStatus"
                onChange={handleStatusChange}
                disabled={loading}
                >
                {statusOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="Icono">
              <Form.Label>Icono</Form.Label>
              { /* <Form.Control placeholder="Icono por defecto" disabled /> */ }
              <Form.Select
                            value={formData.markerIconDesign}
                            name="markerIconDesign"
                            onChange={handleChange}
                            disabled={loading}
                            ref={iconSelectRef}
                          >
                          {allColorOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
              </Form.Select>
            </Form.Group>
          
            <Form.Group as={Col} controlId="Latitud">
              <Form.Label>Latitud</Form.Label>
              <Form.Control type="text" value={formGeolocationData.formGeolocationData.markerLat} disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="Longitud">
              <Form.Label>Longitud</Form.Label>
              <Form.Control type="text" value={formGeolocationData.formGeolocationData.markerLon} disabled />
            </Form.Group>

            <Form.Group controlId="Public" as={Col} >
                          <Form.Label>Marca Pública</Form.Label>
                            <Form.Select
                              value={String(formData.publicAsset)} // convert boolean to string
                              name="publicAsset"
                              onChange={handlePublicAssetChange}
                              disabled={loading}
                            >
                              <option value="true">Si</option> {/* default */}
                              <option value="false">No</option>
                            </Form.Select>
            </Form.Group>

          </Row>
          <Row className="mb-3 g-1" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Notas">
              <Form.Label>Notas / Enlaces</Form.Label>
              <TinymceEditor
                options={{
                  height: '10rem',
                  width: '30rem'
                }}
                value={markerTinyMceContent} onChange={handleTinyMceChange}
              />
            </Form.Group>
          </Row>

          {showForm && (
            <Form.Group>
              <hr></hr>
              <span className="text-body-tertiary fw-semibold">
                <span className="me-5">Opciones Avanzadas</span>
              </span>
              <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
                {markerDataContents.map((item, index) => (
                  <div className="d-flex flex-wrap mb-1 gap-1 gap-sm-4 align-items-center" key={index}>
                    <Form.Group as={Col} controlId={item.contentDataRowTitle}>
                      <Form.Label>{item.contentDataRowTitle}</Form.Label>
                      {item.contentDataType === 'text' && (
                        <Form.Control
                          type="text"
                          placeholder={item.contentDataContent}
                          name="contentDataContent"
                           title={item.contentDataContent}
                          onChange={(event) => handleChange3(event, index)}
                          disabled={loading}
                        />
                      )}
                      {item.contentDataType === 'textarea' && (
                        <Form.Control
                          as="textarea"
                          placeholder="Ingresar..."
                          name="contentDataContent"
                          title={item.contentDataContent}
                          onChange={(event) => handleChange3(event, index)}
                          disabled={loading}
                        />
                      )}
                      {item.contentDataType === 'file' && (
                        <Dropzone accept={{ 'image/gif': [] }} onDrop={acceptedFiles => {
                          setUploadFiles(acceptedFiles); 
                        }} />
                      )}
                    </Form.Group>
                  </div>
                ))}
              </Row>
            </Form.Group>
          )}

          <br></br>
          <Row xs={12} md={6} lg={9} xl={9} xxl={9} sm={7}>
            <Button variant="primary" onClick={handleSave} disabled={loading}>
              {loading ? 'Guardando...' : 'Guardar'}
            </Button>

            <br></br>
            <Button variant="phoenix-secondary" onClick={debugForm}>
              {'Debug'}
            </Button>
          </Row> 
        </Form>
      </Row>
      <Modal show={showSuccessModal} onHide={() => {setShowSuccessModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Marca agregada con éxito<br />
          <b>Si no ve los cambios, por favor actualice la página</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {setShowSuccessModal(false)}}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showErrorModal} onHide={() => { setShowErrorModal(false); setErrorModalMessage('') }}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowErrorModal(false); setErrorModalMessage('') }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      </>
  );
}

const RegistroNuevoPunto = () => {
  const [layerData, setLayerData] = useState<MapLayer[]>([]);
  const [markerData, setMarkerData] = useState<MapMarker[]>([]);

  const [formGeolocationData, setFormGeolocationData] = useState<MarkerCreationMapProps>({
    formGeolocationData: {
      markerLat: 0.0,
      markerLon: 0.0
    }
  });

  const handleMarkerPropsChange = (lat: number, lon: number) => {
    setFormGeolocationData({
      formGeolocationData: {
        markerLat: lat,
        markerLon: lon,
      },
    });
  };

  useEffect(() => {
    (async () => {
        setLayerData(await fetchLayers())
        setMarkerData(await fetchMarkers());
      }
    )();
  }, []);
  
  return (
    <>
    <Row className="gy-3 mb-1 justify-content-between">
        <Col md={9} xs="auto">
          <h2 className="mb-2 text-body-emphasis"><span className="me-1">Registro de marcas</span>{' '}</h2>
          <h5 className="text-body-tertiary fw-semibold">
            Marcas almacenadas serán publicadas al instante
          </h5>
        </Col>
      </Row>
      <Row className="gx-1 border-y">
        <Row className="mb-1 g-1">
          <Col xs={12} xl={12} xxl={12} sm={12}>
            <div className="mx-n4 mx-lg-n1 ms-xl-0 h-100">
              <div className="h-100 w-100" style={{ minHeight: 320 }}>
                <MarkerCreationMap data={markerData} onGeolocationChange={handleMarkerPropsChange} /> { /* MAPA */ }
              </div>
            </div>
          </Col>
        </Row>
      </Row> 

      <MarkerRegisterFormComponent layerData={layerData} formGeolocationData={formGeolocationData}/>
    </>
  );
};

export default RegistroNuevoPunto;