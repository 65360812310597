import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCamera,
  faCheck,
  faClipboard,
  faDiagramProject,
  faDrawPolygon,
  faEnvelope,
  faLayerGroup,
  faPaperclip,
  faRocket,
  faScrewdriverWrench,
  faShoppingBasket,
  faSkullCrossbones,
  faStethoscope,
  faSwatchbook,
  faToolbox,
  faTruck,
  faTruckFast,
  faTruckRampBox,
  faUserGear,
  faUserGraduate,
  faUsersGear,
  faUtensils,
  faVideo
} from '@fortawesome/free-solid-svg-icons';

export interface TimelineData {
  oppositeContent: {
    date: string;
    time: string;
  };
  content: {
    title: string;
    subtitle: string;
  };
  separator: {
    icon: IconProp;
    dotClass: string;
    barClass?: string;
  };
}

export interface TimelineItem {
  id: number;
  time: string;
  icon: IconProp;
  iconColor: string;
  title: string;
  content?: string;
  tasker: string;
  file?: string;
}
export interface Timeline {
  id: number;
  date: string;
  items: TimelineItem[];
}

export const orderTrackingTimelineData: TimelineData[] = [
  {
    oppositeContent: {
      date: '23 August, 2023',
      time: '10:30 AM'
    },
    content: {
      title: 'Order is processing',
      subtitle: 'Your package is ready for the seller to prepare.'
    },
    separator: {
      icon: faCheck,
      dotClass: 'bg-success dark__bg-success',
      barClass: 'border-success'
    }
  },
  {
    oppositeContent: {
      date: '25 August, 2023',
      time: '11:30 AM'
    },
    content: {
      title: 'Picked Up',
      subtitle: 'Your package has been picked up from the seller.'
    },
    separator: {
      icon: faCheck,
      dotClass: 'bg-success dark__bg-success',
      barClass: 'border-warning'
    }
  },
  {
    oppositeContent: {
      date: '27 August, 2023',
      time: '6:30 AM'
    },
    content: {
      title: 'Ready to Ship',
      subtitle: 'Your package is now ready to be shipped.'
    },
    separator: {
      icon: faTruckRampBox,
      dotClass: 'bg-warning dark__bg-warning',
      barClass: 'border-dashed'
    }
  },
  {
    oppositeContent: {
      date: 'Estimated time',
      time: '29 August, 2023'
    },
    content: {
      title: 'Shipped',
      subtitle: 'Pending'
    },
    separator: {
      icon: faTruck,
      dotClass: 'bg-body-quaternary',
      barClass: 'border-dashed'
    }
  },
  {
    oppositeContent: {
      date: 'Estimated time',
      time: '30 August, 2023'
    },
    content: {
      title: 'Delivered',
      subtitle: 'Pending'
    },
    separator: {
      icon: faTruckFast,
      dotClass: 'bg-body-quaternary'
    }
  }
];

export const timelineData: Timeline[] = [
  {
    id: 1,
    date: '¿En qué estamos?',
    items: [
      {
        id: 10,
        time: 's/i',
        icon: faShoppingBasket,
        iconColor: 'danger',
        title: `Tienda catálogo e-Shop`,
        content:
          'Estamos desarrollando un módulo para facilitarte la vida!. Pronto nuestros nuevos módulos y cositas extras estarán en un catálogo de compra rápida. Con un par de clicks podrás acceder a nuevas funciones y optimizar tu plataforma.',
        tasker: 'Root services'
      },
      {
        id: 10,
        time: 's/i',
        icon: faCamera,
        iconColor: 'warning',
        title: `Proyecto XGIS AR`,
        content:
          'Nuestros científicos locos trabajan en llevar la realidad aumentada a tus manos. Desarrollamos nuevas opciones para que tu experiencia en la navegación de usuario pueda interactuar con los dispositivos en tiempo real usando tu ubicación y cámara.',
        tasker: 'Root services'
      },
      {
        id: 11,
        time: 's/i',
        icon: faLayerGroup,
        iconColor: 'danger',
        title: `Personalización de los mapas (layers) por defecto`,
        content:
          'Nuestro equipo trabaja arduamente para mejorar tu experiencia de usuario para darte las opciones de personalización de presentación de los mapas.',
        tasker: 'Root services'
      },
      {
        id: 12,
        time: 's/i',
        icon: faToolbox,
        iconColor: 'danger',
        title: 'Ajustes de sistema mejorados',
        content:
          'Desarrollamos mejoras en los ajustes de sistema para incluir una mejor administración de los recursos de la plataforma.',
        tasker: 'Root services'
      },
      {
        id: 13,
        time: 's/i',
        icon: faUsersGear,
        iconColor: 'info',
        title: 'Mejoras en el perfil de usuario',
        content: 'Trabajamos en mejorar las opciones del perfil de usuarios, donde habilitaremos funciones y diseños adaptativos; Como también, los conectores a otras aplicaciones.',
        tasker: 'Root services'
      },
      {
        id: 14,
        time: 's/i',
        icon: faUserGraduate,
        iconColor: 'warning',
        title: 'Mejoras mapas públicos',
        content: 'Estamos rediseñando la forma en que verán tus registros de forma pública.',
        tasker: 'Root services'
      }
    ]
  },
  {
    id: 2,
    date: 'Abril, 2024',
    items: [
      {
        id: 21,
        time: '24/04/2024 01:30 hrs',
        icon: faDrawPolygon,
        iconColor: 'success',
        title: 'Cambios y mejoras sistema Polígonos',
        content:
          'Cambios y mejoras en módulo administración de polígonos. Quitamos barra de herramientas para mejor desplazamiento en dispositivos móviles y experiencia del usuario en múltiples dispositivos. ',
        tasker: 'Root services'
      },
      {
        id: 22,
        time: '20/04/2024 03:30 hrs',
        icon: faUserGear,
        iconColor: 'success',
        title: 'Habilitación sub-cat menú principal',
        content:
          'Se eliminan las opciones y menús redundantes de la versión. Habilitamos sub-menús para mejorar look&feel de la plataforma.',
        tasker: 'Root services'
      },
      {
        id: 23,
        time: '12/04/2024 09:30 hrs',
        icon: faStethoscope,
        iconColor: 'success',
        title: 'Incorporación del bot CheckHealth',
        content:
          'Desarrollo bot del sistema para chequear la salud de la plataforma y base de datos. Este bot permite al sistema notificar al administrador la integridad de los datos registrados y su salud.',
        tasker: 'Root services'
      },
      {
        id: 24,
        time: '01/04/2024 20:30 hrs',
        icon: faUtensils,
        iconColor: 'success',
        title: 'Incorporación de TimeLine',
        content:
          "Desarrollo menú principal para incorporación de linea de tiempo de desarrollo de nuevos componentes y/o módulos.",
        tasker: 'Root services'
      }
    ]
  },
  {
    id: 3,
    date: 'Marzo, 2024',
    items: [
      {
        id: 25,
        time: '12/03/2024 - 00:00 hrs',
        icon: faRocket,
        iconColor: 'info',
        title: 'Lanzamiento XGIS - Emilia (stable version)',
        content: 'Actualización a nueva versión XGIS.',
        tasker: 'Root services'
      },
      {
        id: 31,
        time: '11/03/2024 - 08:32 hrs',
        icon: faScrewdriverWrench,
        iconColor: 'success',
        title: 'Incorporación Nuevas tablas a registros',
        content:
          'Se proyecta a incorporar para todos los registros; Módulo de tablas dinámicas con buscador y paginador. Con esta incorporación pretendemos facilitar el acceso y búsqueda de la información.',
        tasker: 'Root services'
      },
      {
        id: 32,
        time: '06/03/2024 - 12:00 hrs',
        icon: faDiagramProject,
        iconColor: 'warning',
        title: 'Proyecto actualización interfaz GUI',
        tasker: 'Root services'
      }
    ]
  }
];
