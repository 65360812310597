import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { UserLogs } from 'data/users';
import { Icon } from 'leaflet';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export const UserLogTableColumns: ColumnDef<UserLogs>[] = [
  {
    accessorKey: 'name',
    header: 'Nombre',
    cell: ({ row: { original } }) => {
      const { firstName, lastName } = original;
      return (
        <Link to="#!x" className=" d-flex align-items-center text-body"> 
          <p className="mb-0 ms-3 tex t-body-emphasis fw-semibold">{firstName} {lastName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '50px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Correo',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semibold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'username',
    header: 'ID de Usuario',
    meta: {
      headerProps: { style: { width: '5%' } },
      cellProps: { className: 'text-body' }
    }
  },
  {
    accessorKey: 'logs',
    header: 'Movimientos',
    cell: ({ row: { original } }) => {
      const { _id, firstName, lastName } = original;
      return (
        <Link to={"/configuracion/logs/log-view/" + _id} className="fw-semibold">
          <FontAwesomeIcon icon={faEye} className="warning" />
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '5%', minWidth: '100px' },
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'lastLogin',
    header: 'Última sesión',
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'text-end pe-0'
      },
      cellProps: {
        className: 'text-body-tertiary text-end'
      }
    }
  }
];

const UserLogTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserLogTable;
