import PoligonoTopSection from 'components/modules/poligono/PoligonoTopSection';
import LayerListTablePoligono, { layerListTableColumns } from 'components/tables/CapasListTablePoligono';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useState, useEffect } from 'react';

import { MapLayer, fetchLayers } from 'data/capas/capas';

const PoligonoListView = () => {
  const [layerData, setLayerData] = useState<MapLayer[]>([]);

  const table = useAdvanceTable({
    data: layerData,
    columns: layerListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true,
    selection: true
  });

  const loadData = async () => {
    setLayerData(await fetchLayers());
  };

  // page load
  useEffect(() => {
    loadData();
  }, []);
  
  return (
    <>
      <div>
        <AdvanceTableProvider {...table}>
          <div className="d-flex flex-wrap mb-6 gap-3 gap-sm-6 align-items-center">
            <h2 className="mb-0">
              <span className="me-1">Registro de polígonos</span>{' '}
            </h2>
          </div>
          <PoligonoTopSection activeView="list" />
          <LayerListTablePoligono />
        </AdvanceTableProvider>
      </div>
    </>
  ); 
};

export default PoligonoListView;