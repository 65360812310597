import { useContext, useEffect, useState } from 'react';
import L, { LatLng, LatLngExpression } from 'leaflet';
import { MapContainer, Marker, Popup, useMapEvents, LayersControl, LayerGroup, TileLayer, Polyline, Polygon } from 'react-leaflet';
import 'leaflet.tilelayer.colorfilter/src/leaflet-tilelayer-colorfilter';
import '../../../node_modules/leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css';
import { CategoryRoot, MapMarker } from 'data/puntos/puntos';
import { AppContext } from 'providers/AppProvider';

import { Col, Row, Table } from 'react-bootstrap';
import { MapLayer } from 'data/capas/capas';
import { getLightColor, getMarkerColor } from 'helpers/utils';

import "react-leaflet-fullscreen/styles.css";
import { MapPolygon } from 'data/polygon/polygons';
import MarkerClusterGroup from 'react-leaflet-cluster';

const LayerComponent = ({ 
      markerData, 
      polygonData, 
      selectedLayer,
      coordCache,
      onMapClick 
    }: { 
      markerData: MapMarker[], 
      polygonData: MapPolygon[], 
      selectedLayer: MapLayer, 
      coordCache: any, 
      onMapClick: any 
    }) => {

  const [layerMarkers, setLayerMarkers] = useState<MapMarker[]>([]); // Lista de Markers que corresponden a la capa selectedLayer
  const [layerPolygons, setLayerPolygons] = useState<MapPolygon[]>([]); // Lista de Polygon que corresponden a la capa selectedLayer

  useEffect(() => {
    const filteredMarkers = markerData.filter(marker => {
      const layerId = marker.markerLayer?._id || null;
      return layerId === selectedLayer._id;
    });
  
    setLayerMarkers(filteredMarkers);
  }, [markerData, selectedLayer]);

  useEffect(() => {
    const filteredPolygons = polygonData.filter(polygon => {
      const layerId = polygon.polygonLayer?._id || null;
      return layerId === selectedLayer._id;
    });
  
    setLayerPolygons(filteredPolygons);
  }, [polygonData, selectedLayer]);

  const map = useMapEvents({
    click(event) {
      onMapClick(map, event.latlng); // Enviar lat y lng a PoligonosListEdit
    }
  });
  const { config } = useContext(AppContext);

  const { theme } = config;
  const filter =
    theme === 'dark'
      ? [
          'invert:98%',
          'grayscale:69%',
          'bright:89%',
          'contrast:111%',
          'hue:205deg',
          'saturate:1000%'
        ]
      : ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];

  useEffect(() => {
    map.invalidateSize();
  }, [config]);

  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      
      (L.tileLayer as any)
        .colorFilter(
          'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
          {
            attribution: null,
            transparent: true,
            filter: filter
          }
        )
        .addTo(map);
    }
  }, [theme]);

  return (
    <LayersControl position='topright'>
        <LayersControl.BaseLayer name="x21Map" checked>
          <TileLayer
            attribution='OpenStreet Map'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Google Map">
            <TileLayer
              attribution="Google Maps"
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
        </LayersControl.BaseLayer>
        
        <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer
              attribution='OpenStreet Map'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Mapa Satelital">
            <TileLayer
              attribution='Mapa Satelital'
              url="http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
            />
        </LayersControl.BaseLayer>

      { /* <LayersControl.Overlay name="Marcas" checked>
        <LayerGroup>
        <MarkerClusterGroup>
          {layerMarkers.map(marker => (
            <Marker
              key={marker._id}
              position={[marker.markerLat, marker.markerLon]}
              icon={getMarkerColor(marker.markerIconDesign || marker.markerLayer.layerIconDesign || '')}
            >
              <Popup maxWidth={280} maxHeight={400} className='leaflet-popup leaflet-popup-pane leaflet-zoom-animated'>
                <Row className="g-9 text-body-emphasis">
                  <Col xs={12} xl={12} xxl={12} sm={12}>
                    <h4 className="mb-2 text-body-emphasis fw-semibold">{marker.markerName.toUpperCase()}</h4>
                      <Table striped bordered hover style={{ minHeight: 10, minWidth: 290}}>
                        <tbody>
                          <tr>
                            <td className="fw-semibold"> Autor: </td><td>{marker.markerOwner.username}</td>
                          </tr>
                          <tr>
                            <td className="fw-semibold"> Capa designada: </td><td>{marker.markerLayer.layerName}</td>
                          </tr>
                          <tr>
                            <td className="fw-semibold"> Categoría(s): </td><td>{marker.markerCategory.map((categoria: CategoryRoot) => categoria.categoryTitle)}</td>
                          </tr>
                          <tr>
                            <td className="fw-semibold"> Estado: </td><td>{marker.markerStatus}</td>
                          </tr>
                        </tbody>
                      </Table>
                  </Col>
                </Row>
              </Popup>
            </Marker>
          ))}
          </MarkerClusterGroup>
        </LayerGroup>
      </LayersControl.Overlay> */ }

      <LayersControl.Overlay name="Polígonos" checked>
        <LayerGroup>
          {layerPolygons.map(polygon => (
            <Polygon
              key={polygon._id}
              positions={polygon.polygonCoords.map((coord: string) => JSON.parse(coord))}
              color={selectedLayer.layerIconDesign}
              fillColor={getLightColor(selectedLayer.layerIconDesign)}
              fillOpacity={0.5}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup()
              }}
            >
              <Popup >
                <h5 className="mb-2 text-body-emphasis fw-semibold">{polygon.polygonName}</h5>
              </Popup>
            </Polygon>
          ))}
        </LayerGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name="Polígono en edición" checked>
        <LayerGroup>
          {coordCache.length > 1 && (
            <Polygon 
              positions={coordCache} 
              color={selectedLayer.layerIconDesign} 
              fillColor={getLightColor(selectedLayer.layerIconDesign)} 
              fillOpacity={0.5}
            />
          )}
          {coordCache.length > 0 && (
            <>
              <Marker
                position={coordCache[0]}
                icon={L.divIcon({
                  className: 'custom-icon',
                  html: '<div style="background-color: green; width: 12px; height: 12px; border-radius: 50%;"></div>',
                  iconSize: [12, 12],
                  iconAnchor: [6, 6]
                })}
              />
              <Marker
                position={coordCache[coordCache.length - 1]}
                icon={L.divIcon({
                  className: 'custom-icon',
                  html: '<div style="background-color: red; width: 12px; height: 12px; border-radius: 50%;"></div>',
                  iconSize: [12, 12],
                  iconAnchor: [6, 6]
                })}
              />
            </>
          )}
        </LayerGroup>
      </LayersControl.Overlay>
    </LayersControl>
  );
};

const PolygonCreationMap = ({ markerData, polygonData, selectedLayer, coordCache, setCoordCache, onMapClick, ...rest }: { markerData: MapMarker[], polygonData: MapPolygon[], selectedLayer: MapLayer, coordCache: any, setCoordCache: any, onMapClick: any }) => {
  const position: LatLngExpression = [-33.537, -70.72827];
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <MapContainer
      zoom={isRTL ? 10.8 : 10.5}
      minZoom={isRTL ? 1.8 : 1.3}
      zoomSnap={0.5}
      center={position}
      {...rest}
      className={"h-100 bg-body-emphasis"}
    >
      <LayerComponent markerData={markerData} polygonData={ polygonData } selectedLayer={selectedLayer} coordCache={coordCache} onMapClick={onMapClick}/>
    </MapContainer>
  );
};

export default PolygonCreationMap;
