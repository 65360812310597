import axios from 'axios';
import { apiUrl } from 'helpers/utils';

export interface LogEntry {
  logDate: string;
  logData: string;
}

export const fetchLogs = async (_id: string): Promise<LogEntry[]> => {
  try {
    const response = await axios.get(apiUrl + '/api/logs/get/' + _id, { withCredentials: true });
    
    return response.data.logs;
  } catch (error) {
    console.error('Error logs for: ' + _id, error);
    return [];
  }
};