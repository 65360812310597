import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import IssuesDiscoveredChart from 'components/charts/e-charts/IssuesDiscoveredChart';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

// PROC: 
// fetchMarkers -> look markers & get layers -> add layers to memory -> get layer

export interface LayerInfo {
  id: string;
  name: string;
}

const colorOrder = ['info-light', 'warning-light', 'danger-light', 'success-light', 'primary'];

const IssuesDiscovered = ({ markerData }: { markerData: MapMarker[]; }) => {
  //const [markerData, setMarkerData] = useState<MapMarker[]>([]);
  const [issueStats, setIssueStats] = useState<{ 
    type: string; value: number; bg: string 
  }[]>([]);
  const [layerInfo, setLayerInfo] = useState<LayerInfo[]>([]);
  const [totalMarkers, setTotalMarkers] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const markers = markerData;
      const { groupedMarkers, layerInfo } = groupMarkersByLayerId(markers);
      setLayerInfo(layerInfo);

      const stats = Object.entries(groupedMarkers).map(([layerId, markers], index) => ({
        type: layerInfo.find(info => info.id === layerId)?.name || `Capa ${layerId}`,
        value: markers.length,
        bg: colorOrder[index % colorOrder.length]
      }));

      setIssueStats(stats);

      const total = markers.length;
      setTotalMarkers(total);
    })();
  }, [markerData]);

  const groupMarkersByLayerId = (markers: MapMarker[]) => {
    const groupedMarkers = markers.reduce((grouped, marker) => {
      const layerId = marker.markerLayer._id;
      if (!grouped[layerId]) {
        grouped[layerId] = [];
      }
      grouped[layerId].push(marker);
      return grouped;
    }, {} as Record<string, MapMarker[]>);

    const layerInfo = markers.reduce((info, marker) => {
      const layerId = marker.markerLayer._id;
      if (!info.find(l => l.id === layerId)) {
        info.push({ id: layerId, name: marker.markerLayer.layerName });
      }
      return info;
    }, [] as LayerInfo[]);

    return { groupedMarkers, layerInfo };
  };

  return (
    <Row className="g-3 mb-3">
      <Col xs={12} md={6}>
        <h3 className="text-body-emphasis text-nowrap">Registros descubiertos</h3>
        <p className="text-body-tertiary mb-md-7">
          Recientes y registrados anteriormente
        </p>
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">Tipo de marcas </p>
          <p className="mb-0 fs-9">
            Total marcas <span className="fw-bold">{ totalMarkers }</span>
          </p>
        </div>
        <hr className="bg-body-secondary mb-2 mt-2" />

        {issueStats.map(issue => (
          <div className="d-flex align-items-center mb-1" key={issue.type}>
            <span
              className={`d-inline-block bg-${issue.bg} bullet-item me-2`}
            />
            <p className="mb-0 text-body-tertiary lh-sm flex-1">
              {issue.type}
            </p>
            <p className="mb-0 text-body">{issue.value}</p>
          </div>
        ))}

        <Button disabled variant="outline-primary" className="mt-5">
          Ver detalles
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-primary ms-2 fs-10"
          />
        </Button>
      </Col>
      <Col xs={12} md={6}>
        <div className="position-relative mb-sm-4 mb-xl-0">
          <IssuesDiscoveredChart markerData={markerData} layerInfo={layerInfo} />
        </div>
      </Col>
    </Row>
  );
};

export default IssuesDiscovered;
