import team33 from 'assets/img/team/33.webp';

import axios from 'axios';
import { apiUrl } from 'helpers/utils';

export interface Member {
  id: number;
  name: string;
  avatar?: string;
  username: string;
  connections: number;
  mutual: number;
}

export interface User {
  _id: string;
  username: string;
  email: string;
  status: string;
  firstName: string;
  lastName: string;
  phone: string;
  lastLogin: string;
}

export interface UserLogs {
  _id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  lastLogin: string;
}

export const fetchUsers = async (): Promise<User[]> => {
  try {
    const response = await axios.get(apiUrl + '/api/user/list', { withCredentials: true });
    
    return response.data.content;
  } catch (error) {
    console.error('Error fetching members:', error);
    return [];
  }
};

export const members: Member[] = [
  {
    id: 1,
    name: 'Tyrion Lannister',
    avatar: team33,
    username: 'tyrion222',
    connections: 224,
    mutual: 24
  }
];

export type TeamMember = {
  image: string;
  name: string;
  designation: string;
  facebookHandle: string;
  twitterHandle: string;
  linkedinHandle: string;
};

export const defaultTeamMembers: TeamMember[] = [
  {
    image: team33,
    name: 'John Smith',
    designation: 'CEO, Global Cheat',
    facebookHandle: '#!',
    twitterHandle: '#!',
    linkedinHandle: '#!'
  }
];
