import {
  faChevronRight,
  faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import FaqCta from 'components/cta/FaqCta';
import changelog from 'data/changelog';
import changelogs from 'data/changelog';
import { faqBreadcrumbItems, faqs } from 'data/faq';
import { Accordion, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ChangeLog = () => {
  return (
      <><div><PageBreadcrumb items={faqBreadcrumbItems } />
      <h2 className="mb-1">Sobre {process.env.REACT_APP_NAME}</h2>
      <h5 className="mb-3">Versión del sistema: <p className='text-body-tertiary'>{process.env.REACT_APP_VERSION}</p></h5>
      <h6><Link to="../docs/Politica de Privacidad.pdf">Política de privacidad</Link></h6>
      <p className="text-body-tertiary">
        CopyRight: {process.env.REACT_APP_COPYRIGHT} {' '} - 
        <Link to='../docs/Politica de Terminos y Condiciones.pdf'>Términos & Condiciones</Link>
      </p>
      <h5 className="mb-3">Licencia: <p className='text-body-tertiary'>XXX-YYY-ZZZ-DEMO0027</p></h5>
      <h5 className="mb-3">Expira: <p className='text-body-tertiary'>(indefinido)</p></h5>
      <h5 className="mb-3">Soporte: <p className='text-body-tertiary'>{process.env.REACT_APP_CONTACT}</p></h5>
      <h5 className="mb-3">Contribuciones: <p className='text-body-tertiary'>{process.env.REACT_APP_AUTHORS}</p></h5>
      <Accordion className="" defaultActiveKey="0">
        {faqs.map((faq, index) => (
          <Accordion.Item
            className={classNames({
              'border-top': index === 0
            })}
            eventKey={String(index)}
            key={faq.id}
          >
            <Accordion.Header>{faq.title}</Accordion.Header>
            <Accordion.Body
              className="pt-0"
              dangerouslySetInnerHTML={{ __html: faq.details }} />
          </Accordion.Item>
        ))}
      </Accordion>
      <FaqCta />
    </div>
    
    <div>
        <h2 className="mb-4 lh-sm">Nuestra evolución</h2>

        {changelogs.map(changelog => (
          <PhoenixDocCard className="mb-4" key={changelog.title}>
            <PhoenixDocCard.Header noPreview>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="mb-2">
                    <code className="fw-bold fs-7">v{changelog.version}</code> -{' '}
                    {changelog.title}
                  </h5>
                  <p className="mb-0">{changelog.publishDate}</p>
                </div>
                <Button
                  as={Link}
                  variant="link"
                  to={`/migrations/#${changelog.version}`}
                  endIcon={<FontAwesomeIcon icon={faChevronRight} className="fs-10" />}
                >
                  Ver detalles
                </Button>
              </div>
            </PhoenixDocCard.Header>
            <PhoenixDocCard.Body>
              {changelog.alertText && (
                <Alert
                  variant={'subtle-warning'}
                  className="d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="text-warning fs-5 me-3" />
                  {changelog.alertText}
                </Alert>
              )}

              {/* New */}
              <h6 className="d-inline-block">Nuevo</h6>
              <ul>
                {changelog.logs.new.map(file => (
                  <li dangerouslySetInnerHTML={{ __html: file }} key={file} />
                ))}
              </ul>
              {/* Update */}
              {changelog.logs.update && (
                <>
                  <h6 className="d-inline-block">Actualizado</h6>
                  <ul>
                    {changelog.logs.update.map(file => (
                      <li dangerouslySetInnerHTML={{ __html: file }} key={file} />
                    ))}
                  </ul>
                </>
              )}
              {/* Fix */}
              <h6 className="d-inline-block">Corregido</h6>
              <ul>
                {changelog.logs.fix.map(file => (
                  <li dangerouslySetInnerHTML={{ __html: file }} key={file} />
                ))}
              </ul>
            </PhoenixDocCard.Body>
          </PhoenixDocCard>
        ))}

        <PhoenixDocCard className="mb-4">
          <PhoenixDocCard.Header noPreview>
            <h5 className="mb-2">
              <code className="fw-bold fs-7"><a href="#" target="_blank">v1.0.0</a></code> - Roberta (Release oficial)
            </h5>
            <p className="mb-0">30 Jun, 2020</p>
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body>
            <p className="mb-0">Versión inicial - Proyecto registro GIS Covid Mun. Lo Prado.</p>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>
      </div></>
  );
};

export default ChangeLog;
