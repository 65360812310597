import { MapPolygon } from 'data/polygon/polygons';
import CheckmarkAnimation from 'pages/apps/poligonos/modal_anim/CheckmarkAnimation';
import CrossAnimation from 'pages/apps/poligonos/modal_anim/CrossAnimation';
import { useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import axios from 'axios';
import { apiUrl } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';

const ActionDropdownItemsPolygonEdit = ({ polygon }: {polygon: MapPolygon}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const navigate = useNavigate();

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
  
    axios.delete(apiUrl + '/api/polygon/delete/' + polygon._id, { withCredentials: true })
      .then(response => {
        setShowSuccessModal(true);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud para eliminar el polígono:', error);
        if (error.response && error.response.status === 404) {
          setShowErrorModal(true);
          setErrorModalMessage("El polígono que intenta eliminar no existe.");
        } else {
          setShowErrorModal(true);
          setErrorModalMessage("Hubo un error al eliminar el polígono seleccionado, por favor, intente más tarde.");
        }
      });
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/apps/poligonos/poligonos-list-view');
  }

  return (
    <>
      <Dropdown.Item eventKey="4" className="text-danger" onClick={handleDelete}>
        Eliminar
      </Dropdown.Item>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Polígono eliminado con éxito
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Estás seguro de que deseas eliminar este polígono?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
          <Button variant="danger" onClick={handleConfirmDelete}>Eliminar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionDropdownItemsPolygonEdit;
