import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import ToggleViewButton from 'components/common/ToggleViewbutton';
import FourGrid from 'components/icons/FourGrid';
import NineGrid from 'components/icons/NineGrid';
import { MapLayer } from 'data/capas/capas';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface CapasTopSectionInterface {
  activeView: 'list' | 'board' | 'card';
}

const CapasTopSection = ({ activeView }: CapasTopSectionInterface) => {
  const navigate = useNavigate();
  const { setGlobalFilter, getPrePaginationRowModel, getColumn } =
    useAdvanceTableContext<MapLayer>();
  
  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) => {
      return getPrePaginationRowModel().rows.filter(({ original }) => {
        const layerStatus = original.layerStatus;
        return layerStatus === label || (!layerStatus && label === 'all');
      }).length;
    };
    
    return [
      {
        label: 'Todas',
        value: 'all',
        onClick: () => handleFilterItemClick('layerStatus', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: 'Editando',
        value: 'editando',
        onClick: () => handleFilterItemClick('layerStatus', 'editando'),
        count: getDataCount('editando')
      },
      {
        label: 'Deshabilitadas',
        value: 'deshabilitada',
        onClick: () => handleFilterItemClick('layerStatus', 'deshabilitada'), 
        count: getDataCount('deshabilitada')
      },
      {
        label: 'Activas',
        value: 'activa',
        onClick: () => handleFilterItemClick('layerStatus', 'activa'),
        count: getDataCount('activa')
      },
      {
        label: 'Por Vencer',
        value: 'por vencer',
        onClick: () => handleFilterItemClick('layerStatus', 'por vencer'),
        count: getDataCount('por vencer')
      },
      {
        label: 'Vencidas',
        value: 'vencida',
        onClick: () => handleFilterItemClick('layerStatus', 'vencida'),
        count: getDataCount('vencida')
      }
    ];
  }, [getPrePaginationRowModel()]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Row className="g-3 justify-content-between align-items-center mb-4">
      <Col xs={12} sm="auto">
        <FilterTab tabItems={tabItems} />
      </Col>
      <Col xs={12} sm="auto">
        <div className="d-flex align-items-center gap-1">
          <SearchBox
            onChange={handleSearchInputChange}
            placeholder="Buscar Capas"
            style={{ maxWidth: '30rem' }}
            className="me-3"
          />
          <ToggleViewButton
            tooltip="Vista en lista"
            active={activeView === 'list'}
            onClick={() => {
              navigate('#/apps/capas/capas-list-view');
            }}
          >
            <FontAwesomeIcon icon={faList} className="fs-10" />
          </ToggleViewButton>
          <ToggleViewButton
            tooltip="Vista en Tablero"
            active={activeView === 'board'}
            onClick={() => {
              navigate('#/apps/capas/capas-board-view');
            }}
          >
            <NineGrid />
          </ToggleViewButton>
          <ToggleViewButton
            tooltip="Vista en Tarjeta"
            active={activeView === 'card'}
            onClick={() => {
              navigate('#/apps/capas/capas-card-view');
            }}
          >
            <FourGrid />
          </ToggleViewButton>
        </div>
      </Col>
    </Row>
  );
};

export default CapasTopSection;
