import ProjectElevenProgressChart from 'components/charts/e-charts/ProjectElevenProgressChart';
//import React from 'react';

const ProjectElevenProgress = () => {
  return (
    <>
      <h3>Marcas: proceso evolutivo</h3>
      <p className="text-body-tertiary mb-0 mb-xl-3">Registros &amp; actualizaciones</p>
      <ProjectElevenProgressChart />
    </>
  );
};

export default ProjectElevenProgress;
