import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import ToggleViewButton from 'components/common/ToggleViewbutton';
import FourGrid from 'components/icons/FourGrid';
import NineGrid from 'components/icons/NineGrid';
import { MapMarker, Punto } from 'data/puntos/puntos';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface PuntosTopSectionInterface {
  activeView: 'list' | 'board' | 'card';
}

const PuntosTopSection = ({ activeView }: PuntosTopSectionInterface) => {
  const navigate = useNavigate();
  const { setGlobalFilter, getPrePaginationRowModel, getColumn } =
    useAdvanceTableContext<MapMarker>();

  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) =>
      getPrePaginationRowModel().rows.filter(
        ({ original: { markerStatus } }) => { markerStatus === label }
      ).length;

    return [
      {
        label: 'Todas',
        value: 'all',
        onClick: () => handleFilterItemClick('markerStatus', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: 'En edición',
        value: 'edicion',
        onClick: () => handleFilterItemClick('markerStatus', 'edicion'),
        count: getDataCount('edicion')
      },
      {
        label: 'Vencidas',
        value: 'vencida',
        onClick: () => handleFilterItemClick('markerStatus', 'vencida'),
        count: getDataCount('vencida')
      },
      {
        label: 'Activas',
        value: 'activa',
        onClick: () => handleFilterItemClick('markerStatus', 'activa'),
        count: getDataCount('activa')
      },
      {
        label: 'Desactivadas',
        value: 'desactivada',
        onClick: () => handleFilterItemClick('markerStatus', 'deshabilitada'),
        count: getDataCount('deshabilitada')
      },
      {
        label: 'Por vencer',
        value: 'por vencer',
        onClick: () => handleFilterItemClick('markerStatus', 'por vencer'),
        count: getDataCount('por vencer')
      }
    ];
  }, [getPrePaginationRowModel()]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Row className="g-3 justify-content-between align-items-center mb-4">
      <Col xs={12} sm="auto">
        <FilterTab tabItems={tabItems} />
      </Col>
      <Col xs={12} sm="auto">
        <div className="d-flex align-items-center gap-1">
          <SearchBox
            onChange={handleSearchInputChange}
            placeholder="Buscar Puntos"
            style={{ maxWidth: '30rem' }}
            className="me-3"
          />
          <ToggleViewButton
            tooltip="Vista en lista"
            active={activeView === 'list'}
            onClick={() => {
              navigate('#/apps/puntos/puntos-list-view');
            }}
          >
            <FontAwesomeIcon icon={faList} className="fs-10" />
          </ToggleViewButton>
          <ToggleViewButton
            tooltip="Vista en Tablero"
            active={activeView === 'board'}
            onClick={() => {
              navigate('#/apps/puntos/puntos-board-view');
            }}
          >
            <NineGrid />
          </ToggleViewButton>
          <ToggleViewButton
            tooltip="Vista en Tarjeta"
            active={activeView === 'card'}
            onClick={() => {
              navigate('#/apps/puntos/puntos-card-view');
            }}
          >
            <FourGrid />
          </ToggleViewButton>
        </div>
      </Col>
    </Row>
  );
};

export default PuntosTopSection;
