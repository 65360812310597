import PhoenixDocCard from 'components/base/PhoenixDocCard';
import DocPageHeader from 'components/docs/DocPageHeader';
import DocPagesLayout from 'layouts/DocPagesLayout';
import React, { useState } from 'react';
import img30 from 'assets/img/generic/30.jpg';
import img31 from 'assets/img/generic/31.jpg';
import img32 from 'assets/img/generic/32.jpg';
import img33 from 'assets/img/generic/33.jpg';
import img34 from 'assets/img/generic/34.jpg';
import img35 from 'assets/img/generic/35.jpg';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { SwiperClass, SwiperSlide } from 'swiper/react';
import Swiper from 'components/base/Swiper';
import { Col, Table } from 'react-bootstrap';
import PhoenixContainer from 'components/base/PhoenixContainer';

const SwiperCarousel = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
  return (
    <div>
      <Swiper
        thumbs={{
          swiper:
            thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
        }}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {[img30, img31, img32, img33, img34, img35].map(
          (image, index) => (
            <SwiperSlide className="h-auto" key={index}>
              <div className="pb-1">
                <img src={image} alt="" className="img-fluid rounded-1" />
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper> 
      <Swiper
        onInit={setThumbsSwiper}
        spaceBetween={5}
        slidesPerView={5}
        freeMode={true}
        grabCursor={true}
        navigation={false}
      >
        {[img30, img31, img32, img33, img34, img35].map(
          (image, index) => (
            <SwiperSlide className="h-auto" key={index}>
              <img src={image} alt="" className="img-fluid rounded-1" />
            </SwiperSlide>
          )
        )}
      </Swiper> 
    </div>
  )
};

export default SwiperCarousel;
