import { Dropdown } from 'react-bootstrap';

const ActionDropdownItems = () => {
  const handleDelete = () => {
    alert("Eliminar");
  };

  return (
    <>
      <Dropdown.Item eventKey="4" className="text-danger" onClick={handleDelete}>
        Eliminar
      </Dropdown.Item>
    </>
  );
};

export default ActionDropdownItems;
