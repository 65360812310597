import { Col, Row, } from 'react-bootstrap';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { useEffect, useState } from 'react';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';
import { Spinner } from 'react-bootstrap';

const MainMap = () => {
    const [loading, setLoading] = useState(true);
    
    const [markerData, setMarkerData] = useState<MapMarker[]>([]);
    const [polygonData, setPolygonData] = useState<MapPolygon[]>([]);
    
    useEffect(() => {
        (async () => {
          setLoading(true);
          try {
            const data = await fetchMarkers();
            setMarkerData(data);
            const pData = await fetchPolygons();
            setPolygonData(pData);
          } finally {
            setLoading(false);
          }
        })();
    }, []);

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <>
                    <Row className="gy-3 mb-1 justify-content-between">
                        <Col md={12} xs={10} xl={12} lg={12} xxl={12} sm={12}>
                            <h2 className="mb-2 text-body-emphasis">
                                <span className="me-1">Mapa & Marcas</span>{' '}
                            </h2>
                            <h5 className="text-body-tertiary fw-semibold">
                                Datos generales georreferencias de puntos y capas
                            </h5>
                        </Col>
                    </Row>
                    <Row className="gx-1 border-y">
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                            <div className="h-100 w-100" style={{ minHeight: 470 }}>
                                <EcomTopRegionsMap markerData={markerData} polygonData={polygonData} />
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

export default MainMap;
