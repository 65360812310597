/*import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
echarts.use([TooltipComponent, PieChart]);

const getDefaultOptions = (
  getThemeColor: (name: string) => string,
  isDark: boolean
) => ({
  color: [
    !isDark ? getThemeColor('info-light') : getThemeColor('info-dark'),
    !isDark ? getThemeColor('warning-light') : getThemeColor('warning-dark'),
    !isDark ? getThemeColor('danger-light') : getThemeColor('danger-dark'),
    !isDark ? getThemeColor('success-light') : getThemeColor('success-dark'),
    getThemeColor('primary')
  ],
  tooltip: {
    trigger: 'item'
  },
  responsive: true,
  maintainAspectRatio: false,

  series: [
    {
      name: 'Registros totales',
      type: 'pie',
      radius: ['48%', '90%'],
      startAngle: 30,
      avoidLabelOverlap: false,
      // label: {
      //   show: false,
      //   position: 'center'
      // },

      label: {
        show: false,
        position: 'center',
        formatter: '{x|{d}%} \n {y|{b}}',
        rich: {
          x: {
            fontSize: 31.25,
            fontWeight: 800,
            color: getThemeColor('tertiary-color'),
            padding: [0, 0, 5, 15]
          },
          y: {
            fontSize: 12.8,
            color: getThemeColor('tertiary-color'),
            fontWeight: 600
          }
        }
      },
      emphasis: {
        label: {
          show: true
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 78, name: 'EMPRESAS QUIMICAS' },
        { value: 63, name: 'DESECHOS COMBUSTIBLES' },
        { value: 56, name: 'QUEMAS CONTROLADAS' },
        { value: 36, name: 'PREDIOS AGRICOLA' },
        { value: 24, name: 'BODEGAS QUIMICOS' }
      ]
    }
  ],
  grid: {
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    containLabel: false
  }
});

function IssuesDiscoveredChart() {
  const {
    getThemeColor, config: { isDark }
  } = useAppContext();

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor, isDark)}
      style={{ minHeight: '390px', width: '100%' }} />
  );
}

export default IssuesDiscoveredChart;
*/

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LayerInfo } from 'components/modules/project-management/dashboard/IssuesDiscovered';
import { MapMarker } from 'data/puntos/puntos';
echarts.use([TooltipComponent, PieChart]);

const getDefaultOptions = (
  getThemeColor: (name: string) => string,
  isDark: boolean,
  data: { value: number; name: string }[]
) => ({
  color: [
    !isDark ? getThemeColor('info-light') : getThemeColor('info-dark'),
    !isDark ? getThemeColor('warning-light') : getThemeColor('warning-dark'),
    !isDark ? getThemeColor('danger-light') : getThemeColor('danger-dark'),
    !isDark ? getThemeColor('success-light') : getThemeColor('success-dark'),
    getThemeColor('primary')
  ],
  tooltip: {
    trigger: 'item'
  },
  responsive: true,
  maintainAspectRatio: false,

  series: [
    {
      name: 'Registros totales',
      type: 'pie',
      radius: ['48%', '90%'],
      startAngle: 30,
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
        formatter: '{x|{d}%} \n {y|{b}}',
        rich: {
          x: {
            fontSize: 31.25,
            fontWeight: 800,
            color: getThemeColor('tertiary-color'),
            padding: [0, 0, 5, 15]
          },
          y: {
            fontSize: 12.8,
            color: getThemeColor('tertiary-color'),
            fontWeight: 600
          }
        }
      },
      emphasis: {
        label: {
          show: true
        }
      },
      labelLine: {
        show: false
      },
      data
    }
  ],
  grid: {
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    containLabel: false
  }
});

function IssuesDiscoveredChart({ markerData, layerInfo }: { markerData: MapMarker[]; layerInfo: LayerInfo[] }) {
  const {
    getThemeColor, config: { isDark }
  } = useAppContext();

  const pieChartData = layerInfo.map(layer => {
    const markersInLayer = markerData.filter(marker => marker.markerLayer._id === layer.id);
    return { value: markersInLayer.length, name: layer.name };
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor, isDark, pieChartData)}
      style={{ minHeight: '390px', width: '100%' }} />
  );
}

export default IssuesDiscoveredChart;