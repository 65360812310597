import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, ChangeEvent, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { UserLogs, fetchUsers } from 'data/users';

import { faFileExport, faPlus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import UserLogTable, { UserLogTableColumns } from 'components/tables/UserLogListTable';
import { LogEntry } from 'data/logs/logs';

const UserLog = () => {
  const [UserLogData, setUserLogData] = useState<UserLogs[]>([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setUserLogData(await fetchUsers());
    })();
  }, []);
  
  const table = useAdvanceTable({
    data: UserLogData,
    columns: UserLogTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Bitácora de usuarios</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-3">
            <Row>
              <Col className="mb-3" xs={10} md={10} lg={10} xl={10} xxl={10} sm={10}>
                <SearchBox
                  placeholder="Buscar Usuarios"
                  onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <UserLogTable  />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default UserLog;