import { Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import EcomStats from 'components/stats/EcomStats';

import { useEffect, useState } from 'react';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';

const Ecommerce = () => {
  const [loading, setLoading] = useState(true);
  
  const [markerData, setMarkerData] = useState<MapMarker[]>([]);
  const [polygonData, setPolygonData] = useState<MapPolygon[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setMarkerData(await fetchMarkers());
        setPolygonData(await fetchPolygons());
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  
  return (
  <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    ) : (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} xxl={6}>
            <div className="mb-8">
              <h2 className="mb-2">Resumen informativo</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Resumen y estadísticas de registros
              </h5>
            </div>
            <EcomStats markerData={ markerData }/>
            <hr className="bg-body-secondary mb-6 mt-4" />
          </Col>
        </Row>
      </div>
      <Row className="gx-1">
        <Col xs={12} sm={12} md={12} xl={12} lg={12}>
          <Card className="h-100 w-100">
            <div className="h-100 w-100" style={{ minHeight: 500 }}>
              <EcomTopRegionsMap markerData={markerData} polygonData={polygonData}/>
            </div>
          </Card>
        </Col>
      </Row>
      </>
    )}
    </>
  );
};

export default Ecommerce;