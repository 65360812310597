import PuntosTopSection from 'components/modules/puntos/PuntosTopSection';
import PuntosListTable, {
  markerListTableColumns
} from 'components/tables/PuntosListTable';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

import IssuesDiscovered from 'components/modules/project-management/dashboard/IssuesDiscovered';
import { Col, Row } from 'react-bootstrap';
import ProjectElevenProgress from 'components/modules/project-management/dashboard/ProjectElevenProgress';
import { useEffect, useState } from 'react';

  const ProjectManagement = () => {
    const [markerData, setMarkerData] = useState<MapMarker[]>([]);

    const table = useAdvanceTable({
      data: markerData,
      columns: markerListTableColumns,
      pageSize: 6,
      pagination: true,
      sortable: true,
      selection: true
    });

    const loadData = async () => {
      setMarkerData(await fetchMarkers());
    };

    // page load
    useEffect(() => {
      loadData();
    }, []);
 
    return (
      <>
        <Row className="gy-3 mb-6 justify-content-between">
          <Col md={9} xs="auto">
            <h3 className="mb-2 text-body-emphasis"><span className="me-1">Administración de marcas</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({markerData.length})
            </span></h3>
            <h5 className="text-body-tertiary fw-semibold">
              Puntos de marcado registrados en el sistema
            </h5>
          </Col>
        </Row>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 pb-3 border-y mb-3">
          <Row>
            <Col xs={12} xl={7} xxl={6}>
              <IssuesDiscovered markerData={ markerData } />
            </Col>
            <Col xs={12} xl={5} xxl={6}>
              <ProjectElevenProgress />
            </Col>
          </Row>
        </div>
        <div>
      <AdvanceTableProvider {...table}>
        <PuntosTopSection activeView="list" />
        <PuntosListTable />
      </AdvanceTableProvider>
    </div>
      </>
    );
  };
export default ProjectManagement;