import Lottie from 'react-lottie';
import animationData from './warning.json'; // Asegúrate de que la ruta sea correcta

const WarningAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={100} width={100} />;
};

export default WarningAnimation;