import { useState, useEffect } from 'react';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  faListAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import WarningAnimation from '../poligonos/modal_anim/WarningAnimation';
import { ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { apiUrl } from 'helpers/utils';
import CrossAnimation from '../poligonos/modal_anim/CrossAnimation';
import CheckmarkAnimation from '../poligonos/modal_anim/CheckmarkAnimation';
import { useNavigate } from 'react-router-dom';
import { MapLayer, fetchLayers } from 'data/capas/capas';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import { User, fetchUsers } from 'data/users';
import { LogEntry, fetchLogs } from 'data/logs/logs';
import { ColumnDef } from '@tanstack/react-table';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';

const LogDetailView = () => {
  const [loading, setLoading] = useState(true);
  
  const [membersData, setMembersData] = useState<User[]>([]);
  
  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const navigate = useNavigate();

  const { id } = useParams();

  const loadData = async () => {
    setMembersData(await fetchUsers());

    setLoading(false);
  };

  const loadUser = async (_id: string | undefined) => {
    if (!_id) {
      alert("No se ha detectado un usuario en la solicitud.");
      return;
    }
    
    const user = membersData.find(user => user._id === _id);
    if (user) {
      setSelectedUser(user);
      setLogEntries(await fetchLogs(user._id));
    } else {
      setShowErrorModal(true);
      setErrorModalMessage("El usuario con el identificador " + _id + " no existe en la base de datos.");

      return;
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    //navigate('/apps/capas/capas-list-view');
  }

  // page load
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      loadUser(id);
    }
  }, [loading, id]);
  
  function convertirFecha(fakeDate: Date): string {
    const date = new Date(fakeDate);
    const dia = date.getUTCDate().toString().padStart(2, '0');
    const mes = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const año = date.getUTCFullYear();
    const horas = date.getUTCHours().toString().padStart(2, '0');
    const minutos = date.getUTCMinutes().toString().padStart(2, '0');
    const segundos = date.getUTCSeconds().toString().padStart(2, '0');
    
    return `${dia}-${mes}-${año} ${horas}:${minutos}:${segundos}`;
}

  const columns: ColumnDef<LogEntry>[] = [
    {
      accessorKey: 'logDate',
      header: 'Fecha',
      cell: ({ row: { original } }) => {
        const { logDate } = original;
        const date = new Date(logDate);
  
        return convertirFecha(date);
      },
    },
    {
      accessorKey: 'logData',
      header: 'Contenido'
    }
  ];
  
  const table = useAdvanceTable({
    data: logEntries,
    columns,
    pageSize: 10,
    pagination: true,
    selection: false,
    sortable: true
  });

  return (
    <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </div>
    ) : (
      <>
        <div className="mb-8">
        {selectedUser && ( 
          <>
            <h2 className="mb-2">Usuario { selectedUser.firstName } { selectedUser.lastName }</h2>
          </> 
        )}
        <div className="mb-3">  
          <Button
            as={Link}
            to="/configuracion/logs/log-list"
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faListAlt} className="fs-10" />}
          >
            Volver a lista
          </Button>
        </div>
        {membersData && selectedUser && (
          <>
            {/* JSON.stringify(logEntries) */}
            
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                tableProps={{
                  size: 'sm',
                  className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
                }}
                rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
              />
              <AdvanceTableFooter pagination />
            </AdvanceTableProvider>


          </>
        )}
        </div>
      </>
    )}

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Capa eliminada con éxito
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => {
        setShowErrorModal(false);
        navigate("/configuracion/logs/log-list");
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
              setShowErrorModal(false);
              navigate("/configuracion/logs/log-list");
            }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogDetailView;