import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Punto, MapMarker } from 'data/puntos/puntos';
//import Avatar from 'components/base/Avatar';
import { ProgressBar } from 'react-bootstrap';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import Badge, { getBadgeBg, getStatusDisplay } from 'components/base/Badge';

export const markerListTableColumns: ColumnDef<MapMarker>[] = [
  {
    accessorKey: 'markerName',
    header: 'Nombre de Marca',
    cell: ({ row: { original }}) => {
      const { markerName, _id } = original;
      return (
        <Link to={ "../puntos-detalles/" + _id } className="text-decoration-none fs-12">
          { markerName.toUpperCase() }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '25%'}}
    }
  },
  {
    accessorKey: 'markerLayer',
    header: 'Capa en referencia',
    cell: ({ row: { original } }) => {
      const { markerLayer } = original;
      return (
        markerLayer.layerName
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '25%' } }
    }
  },
  {
    accessorKey: 'markerInsertDate',
    header: 'Registro',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '10%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Agregada por',
    accessorKey: 'markerOwner',
    cell: ({ row: { original }}) => {
      const { markerOwner } = original;

      console.log(original)
      return (
        <Link to="#" className="text-decoration-none fs-12">
          { markerOwner.firstName + " " + markerOwner.lastName }
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Latitud',
    accessorKey: 'markerLat',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '12%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Longitud',
    accessorKey: 'markerLon',
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '5%' }, className: 'ps-1' }
    }
  },
  {
    id: 'markerStatus',
    header: 'Estado',
    accessorFn: ({ markerStatus }) => markerStatus,
    cell: ({ row: { original } }) => {
      const { markerStatus } = original;
      return (
        <Badge variant="phoenix" bg={getBadgeBg(markerStatus)}>
          {getStatusDisplay(markerStatus)}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  }
];
/*
export const puntosListTableColumns: ColumnDef<Punto>[] = [
  {
    accessorKey: 'nombrepunto',
    header: 'Marcas',
    cell: ({ row: { original } }) => {
      const { nombrepunto } = original;
      return (
        <Link to="#!" className="text-decoration-none fs-12">
          {nombrepunto}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '25%' } }
    }
  },
  {
    accessorKey: 'name',
    header: 'Capa Asignada',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to="#!" className="text-decoration-none fs-12">
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-1' },
      headerProps: { style: { width: '25%' } }
    }
  },
  {
    header: 'Registro',
    accessorKey: 'start',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '10%' }, className: 'ps-1' }
    }
  },
  {
    header: 'Owner',
    accessorKey: 'deadline',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-1' },
      headerProps: { style: { width: '15%' }, className: 'ps-1' }
    }
  },
  {
    accessorKey: 'task',
    header: 'Latitud',
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '12%' }, className: 'ps-1' }
    }
  },
  {
    accessorKey: 'progress',
    header: 'Longitud',
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '5%' }, className: 'ps-1' }
    }
  },
  {
    id: 'status',
    header: 'Estado',
    accessorFn: ({ status }) => status.label,
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge variant="phoenix" bg={status.type}>
          {status.label}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
]; */

const PuntosListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default PuntosListTable;
